import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Img,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode,
  useBreakpointValue,
  useMultiStyleConfig,
  useToast,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Config1Display from '../../../currentBot/components/GenericConfigDisplay';
import { AlertDialogComponent } from '../../../currentBot/components/Sidebar';
import { IAccount, IPositionStore } from '../../../store/botConfig';
import {
  IFutureFormConfig,
  IFutureInstance,
  ITrade,
} from '../../../store/futureConfig';
import { to_f } from '../../../store/utils';
import { RowWrapper } from './RowWrapper';
import { DropdownMenu } from '../../../currentBot/components/DropdownMenu';

interface UserProps {
  data: {
    image: string;
    name: string;
    email: string;
  };
}
const User = (props: UserProps) => {
  const { image, name, email } = props.data;
  return (
    <Stack direction="row" spacing="4" align="center">
      <Box flexShrink={0} h="10" w="10">
        <Img
          objectFit="cover"
          htmlWidth="160px"
          htmlHeight="160px"
          w="10"
          h="10"
          rounded="full"
          src={image}
          alt=""
        />
      </Box>
      <Box>
        <Box fontSize="sm" fontWeight="medium">
          {name}
        </Box>
        <Box fontSize="sm" color="gray.500">
          {email}
        </Box>
      </Box>
    </Stack>
  );
};

const columns = [
  {
    Header: 'Entry',
    accessor: 'entry',
  },
  {
    Header: 'Risk',
    accessor: 'risk',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Avg.E',
    accessor: 'avg_entry',
  },
  {
    Header: 'Avg.q',
    accessor: 'avg_size',
  },
  {
    Header: 'Sell price',
    accessor: 'sell_price',
  },
  {
    Header: 'Stop',
    accessor: 'stop',
  },
  {
    Header: 'Pnl',
    accessor: 'pnl',
  },
  {
    Header: 'neg.Pnl',
    accessor: 'neg_pnl',
  },
  {
    Header: 'Fee',
    accessor: 'fee',
  },
  // {
  //   Header: 'Net Fee',
  //   accessor: 'net',
  // },
  {
    Header: 'Incurred',
    accessor: 'incurred',
  },
  {
    Header: 'Min.Pnl',
    accessor: 'min_pnl',
  },
  // {
  //   Header: 'Risk Sell',
  //   accessor: 'risk_sell',
  // },
];

const controlledColums = [
  {
    Header: 'Entry',
    accessor: 'entry',
  },
  {
    Header: 'Avg',
    accessor: 'avg_entry',
  },
  {
    Header: 'Avg.Qty',
    accessor: 'avg_qty',
  },
  {
    Header: 'Liquidation',
    accessor: 'liquidation',
  },
  {
    Header: 'Opposite.Qty',
    accessor: 'opposite_qty',
  },
  {
    Header: 'Expected loss',
    accessor: 'loss_pnl',
  },
  {
    Header: 'Amount to sell',
    accessor: 'sell_amount',
  },
];

const mainColumns = [
  {
    Header: 'Entry',
    accessor: 'entry',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Stop',
    accessor: 'stop',
  },
  {
    Header: 'Avg.E',
    accessor: 'avg_entry',
  },
  {
    Header: 'Avg.q',
    accessor: 'avg_size',
  },
  {
    Header: 'Pnl',
    accessor: 'pnl',
  },
  {
    Header: 'Min.Pnl',
    accessor: 'min_pnl',
  },
];
export const TablePagination: React.FC<{
  store: IFutureInstance;
  subAccounts?: string[];
  labels?: { heading: string; value: string; isClickable?: boolean }[];
  loading?: boolean;
  hideMargin?: boolean;
  onPlaceOrder: (
    text?:
      | 'margin'
      | 'spot'
      | 'future'
      | 'update_signal'
      | 'cancel_signal'
      | 'place_signal'
      | 'place_margin_signal'
      | 'update_margin_signal'
      | 'place_stop_order'
      | 'cancel_margin_signal'
      | 'reduce_position',
  ) => any;
  onRiskChange: (risk: number) => any;
  removeInput?: boolean;
}> = observer(
  ({
    store,
    subAccounts = [],
    loading,
    labels = [],
    onPlaceOrder,
    onRiskChange,
    hideMargin,
    removeInput = true,
  }) => {
    return (
      <>
        <Flex
          align="center"
          justify="space-between"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <Flex>
            {labels.map((label, key) => {
              return (
                <>
                  <Text
                    textAlign="center"
                    color={mode('gray.600', 'gray.400')}
                    fontSize="sm"
                  >
                    <Text fontWeight="bold">{label.heading}</Text>
                    {label.isClickable ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          onRiskChange(parseFloat(label.value));
                        }}
                      >
                        {label.value}
                      </a>
                    ) : (
                      <>{label.value}</>
                    )}
                  </Text>
                  <Box boxSize={3} />
                </>
              );
            })}
          </Flex>
          <Box boxSize="1" />
          <ButtonGroup
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            variant="outline"
            size="sm"
          >
            {removeInput && (
              <FormControl>
                <FormLabel>Input Type</FormLabel>
                <Checkbox
                  defaultChecked={store.as_select}
                  onChange={store.toggleField}
                >
                  Checkbox
                </Checkbox>
              </FormControl>
            )}
            {/* <FormControl display="flex" id="filter">
						<FormLabel alignSelf="center">Min Filter</FormLabel>
						<Input
							type="number"
							width={'60%'}
							defaultValue={store.minimum}
							onChange={(e) => store.setMinimum(parseFloat(e.target.value))}
							placeholder="Select Account"
						/> 
					</FormControl> */}
            <FormControl display="flex" id="symbol">
              <FormLabel alignSelf="center">Symbol</FormLabel>
              <Input
                defaultValue={store.symbol}
                onChange={(e) => store.setSymbol(e.target.value)}
                placeholder="Select Account"
              />
            </FormControl>
            <FormControl display="flex" id="country">
              <FormLabel alignSelf="center">Owner</FormLabel>
              <Select
                defaultValue={store.account}
                onChange={(e) => store.setAccount(e.target.value)}
                placeholder="Select Account"
              >
                {subAccounts.map((act) => {
                  return <option>{act}</option>;
                })}
              </Select>
            </FormControl>
            <Button
              colorScheme="green"
              isLoading={loading}
              loadingText="Placing order"
              size="lg"
              isDisabled={!store.canPlaceTrade('future')}
              onClick={() => onPlaceOrder('future')}
              rel="next"
            >
              Future
            </Button>
            <Button
              colorScheme="blue"
              isLoading={loading}
              loadingText="Placing order"
              size="lg"
              // isDisabled={!store.canPlaceTrade('spot')}
              onClick={() =>
                store.analyzePosition().then((r) => console.log(r))
              }
              rel="next"
            >
              Losses
            </Button>
            {/* <Button
					colorScheme="blue"
					isLoading={loading}
					loadingText="Placing order"
					size="lg"
					isDisabled={!store.canPlaceTrade('spot')}
					onClick={() => onPlaceOrder('spot')}
					rel="next"
				>
					spot
				</Button> */}
            <Box bgSize={2} />
            <Button
              colorScheme="purple"
              isLoading={loading}
              loadingText="Placing order"
              size="lg"
              isDisabled={!store.canPlaceTrade('margin')}
              onClick={() => onPlaceOrder('margin')}
              rel="next"
            >
              margin
            </Button>
          </ButtonGroup>
        </Flex>
        <Stack
          spacing={3}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Button
            colorScheme="green"
            isLoading={loading}
            loadingText="Placing order"
            size="lg"
            onClick={() => onPlaceOrder('place_signal')}
            rel="next"
          >
            Place Signal Trade
          </Button>
          <Button
            colorScheme="red"
            isLoading={loading}
            loadingText="Placing order"
            size="lg"
            onClick={() => onPlaceOrder('cancel_signal')}
            rel="next"
          >
            Cancel Signal Trade
          </Button>
          <Button
            colorScheme="purple"
            isLoading={loading}
            loadingText="Placing order"
            size="lg"
            onClick={() => onPlaceOrder('update_signal')}
            rel="next"
          >
            Update Take Profit
          </Button>
          <DropdownMenu
            options={[
              {
                label: 'Place Stop Order',
                value: 'place_stop_order',
              },
              {
                label: 'Place Minimum Stop Order',
                value: 'place_minimum_stop_order',
              },
            ]}
            label="Place Stop Order"
            transform={(x) => x.label}
            colorScheme="orange"
            isLoading={loading}
            loadingText="Placing stop order"
            size="lg"
            onClick={(o) => onPlaceOrder(o.value)}
          />
          {/* <Button
            colorScheme="orange"
            isLoading={loading}
            loadingText="Placing stop order"
            size="lg"
            onClick={() => onPlaceOrder('place_stop_order')}
            rel="next"
          >
            Place Stop Order
          </Button> */}
          <Button
            colorScheme="yellow"
            isLoading={loading}
            loadingText="Placing order"
            size="lg"
            onClick={() => onPlaceOrder('reduce_position')}
            rel="next"
          >
            Reduce Position
          </Button>
        </Stack>
        {!hideMargin && (
          <Stack
            mt={2}
            spacing={3}
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            <AlertDialogComponent
              colorScheme="blue"
              isLoading={loading}
              loadingText="Placing order"
              size="lg"
              confirmSummary={'Are you sure you want to place a new trade'}
              heading={'Place Margin Trade'}
              onActionClick={() => onPlaceOrder('place_margin_signal')}
              buttonText="Place Margin Signal"
              confirmText={'Place Margin Signal'}
              rel="next"
            />
            {/* <Button
						colorScheme="blue"
						isLoading={loading}
						loadingText="Placing order"
						size="lg"
						onClick={() => onPlaceOrder('place_margin_signal')}
						rel="next"
					>
						Place Margin Signal
					</Button> */}
            {/* <Button
						colorScheme="orange"
						isLoading={loading}
						loadingText="Placing order"
						size="lg"
						onClick={() => onPlaceOrder('cancel_margin_signal')}
						rel="next"
					>
						Cancel Margin Trade
					</Button> */}
            <AlertDialogComponent
              colorScheme="cyan"
              isLoading={loading}
              loadingText="Placing order"
              size="lg"
              onActionClick={() => onPlaceOrder('update_margin_signal')}
              rel="next"
              confirmSummary={
                'Are you sure you want to close the margin trade?'
              }
              heading={'Close margin trade'}
              buttonText="Update Margin Profit"
              confirmText={'Update Margin Profit'}
            />
            {/* <Button
						colorScheme="cyan"
						isLoading={loading}
						loadingText="Placing order"
						size="lg"
						onClick={() => onPlaceOrder('update_margin_signal')}
						rel="next"
					>
						Update Margin Profit
					</Button> */}
          </Stack>
        )}
      </>
    );
  },
);

function getTradeSize(row) {
  const { entry, pnl } = row;
  console.log('entry,pnl', entry, pnl);
}

export const TableContent: React.FC<{
  onCreate?: (trade: ITrade) => Promise<any>;
  onTrade?: (trade: ITrade) => Promise<any>;
  store?: IFutureInstance;
  data?: Array<ITrade>;
  kind?: 'long' | 'short';
  _columns?: Array<{
    Header: 'Entry';
    accessor: 'entry';
  }>;
  displayEdit?: boolean;
  onUpdateTakeProfit: (trade: ITrade, key?: string) => any;
}> = ({
  data,
  onCreate,
  kind = 'long',
  onUpdateTakeProfit,
  onTrade,
  store,
  displayEdit = true,
  _columns = columns,
}) => {
    const style = useMultiStyleConfig('DisplayTable', {
      kind,
    });

    return (
      <TableContainer flex="1" maxWidth={['380px', '100%', '100%']}>
        <Table sx={style} my="3" borderWidth="1px" variant="simple" fontSize="sm">
          <Thead bg={mode('gray.50', 'gray.800')}>
            <Tr>
              {_columns.map((column, index) => (
                <Th scope="col" key={index}>
                  {column.Header}
                </Th>
              ))}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((row, index) => (
              <Tr key={row.entry}>
                {_columns.map((column, index) => {
                  const cell = row[column.accessor as keyof typeof row];
                  const element = cell;
                  const options = {
                    stop: 'link',
                    entry: 'link',
                    avg_size: 'tooltip',
                    avg_entry: 'popover',
                  };
                  return (
                    <Td key={index}>
                      <RowWrapper
                        wrapperStyle={options[column.accessor]}
                        store={store}
                        hoverText={{
                          ...row,
                          kind: kind === 'long' ? 'short' : 'long',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          getTradeSize(row);
                          console.log('data', data);
                          onUpdateTakeProfit(row, column.accessor);
                        }}
                      >
                        {element}
                      </RowWrapper>
                    </Td>
                  );
                })}
                <Td textAlign="right">
                  {displayEdit && (
                    <Button
                      onClick={() => onCreate(row)}
                      variant="link"
                      colorScheme="blue"
                    >
                      Edit
                    </Button>
                  )}
                </Td>
                {/* {onTrade && (
							<Td textAlign="right">
								<Button
									onClick={() => onTrade(row)}
									variant="link"
									colorScheme="blue"
								>
									Trade
								</Button>
							</Td>
						)} */}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

const TableActions: React.FC<{
  config: IFutureFormConfig;
  hideFields?: Array<string>;
  onChange: (key: string, value: any) => void;
}> = observer(({ config, onChange, hideFields = [] }) => {
  return (
    <Config1Display
      hideFields={hideFields}
      props={{ mt: 3, columns: 3, spacing: 5 }}
      override="config_1"
      store={config}
      onChange={onChange}
    />
  );
});
type DisplayTableContentType = {
  store: IFutureInstance;
  displayControls?: boolean;
};
const DisplayTableContent = observer(
  ({ store, displayControls }: DisplayTableContentType) => {
    let [loading, setLoading] = React.useState(false);
    let toast = useToast();
    const [newKind, setNewKind] = React.useState(store.config.kind);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [isEntryLoading, setIsEntryLoading] = React.useState(false);
    const [actionLoading, setActionLoading] = React.useState(false);

    function buildTrades() {
      const { mode } = store.config;
      let result = [];
      if (['default', 'main'].includes(mode)) {
        result = store.trades_with_avg;
      } else {
        result = store.hedge_full_orders;
      }
      return result;
    }
    function onUpdateTakeProfit(trade: ITrade, key?: string) {
      if (key === 'stop') {
        store.config.updateFields({
          take_profit: trade.stop,
        });
      }
      if (key === 'entry') {
        store.config.updateFields({
          stop: trade.entry,
        });
        if (store.as_select) {
          store.toggleField();
        }
      }
    }
    function onSuccess() {
      setLoading(false);
      toast({
        title: 'Success',
        description: 'Request placed',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
    function onError(error: any) {
      setLoading(false);
      //console.log(error);
      toast({
        title: 'Error',
        description: 'Error placing order',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    if (store.config.mode === 'main') {
      const long_trades = store.buildKindOrders({ kind: 'long' }) || [];
      const short_trades = store.buildKindOrders({ kind: 'short' }) || [];
      const long_avg = store.determine_avg('long');
      const short_avg = store.determine_avg('short');
      const long_total = long_trades
        .filter((r) => r.entry >= store.config.currentEntry)
        .map((o) => o.quantity)
        .reduce((a, b) => a + b, 0);
      const short_total = short_trades
        .filter((r) => r.entry <= store.config.currentEntry)
        .map((o) => o.quantity)
        .reduce((a, b) => a + b, 0);
      const long_display = (
        <Box width="full">
          {displayControls && (
            <Flex justify={'space-between'}>
              <Box pt={5} color={'blue.700'}>
                Avg: {to_f(long_total, '%.3f')}
              </Box>
              <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                <Text fontWeight="bold">{long_avg.heading}</Text>
                <>{long_avg.value}</>
              </Text>
            </Flex>
          )}
          <TableContent
            data={long_trades}
            kind={'long'}
            displayEdit={false}
            _columns={mainColumns.filter((i) => {
              if (store.config.currentEntry) {
                return i.accessor !== 'stop';
              }
              return i.accessor !== 'pnl';
            })}
            onUpdateTakeProfit={(trade: ITrade, key?: string) => {
              console.log('trade', trade);
              store.config.updateFields({
                support: trade.stop,
                resistance: trade.entry,
              });
            }}
            onTrade={(trade) => {
              setLoading(true);
              return store
                .placeSingleOrder(trade, store.config.kind)
                .then(onSuccess)
                .catch(onError);
            }}
            onCreate={async (trade) => {
              console.log(trade);
            }}
          />
        </Box>
      );
      const short_display = (
        <Box width="full">
          {displayControls && (
            <Flex justify="space-between">
              <Box pt={5} color={'red.700'}>
                Avg: {to_f(short_total, '%.3f')}
              </Box>
              <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                <Text fontWeight="bold">{short_avg.heading}</Text>
                <>{short_avg.value}</>
              </Text>
            </Flex>
          )}

          <TableContent
            data={short_trades}
            kind={'short'}
            displayEdit={false}
            _columns={mainColumns.filter((i) => {
              if (store.config.currentEntry) {
                return i.accessor !== 'stop';
              }
              return i.accessor !== 'pnl';
            })}
            onUpdateTakeProfit={(trade: ITrade, key?: string) => {
              console.log('trade', trade);
              if (key === 'entry') {
                store.config.updateFields({
                  support: trade.entry,
                  resistance: trade.stop,
                });
              }
            }}
            onTrade={(trade) => {
              setLoading(true);
              return store
                .placeSingleOrder(trade, store.config.kind)
                .then(onSuccess)
                .catch(onError);
            }}
            onCreate={async (trade) => {
              console.log(trade);
            }}
          />
        </Box>
      );
      return (
        <Box>
          <Box pt={3} fontWeight={'bold'}>
            Support: {store.config.support} Resistance:{' '}
            {store.config.resistance}
          </Box>
          <Flex
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            {store.config.kind === 'long' ? (
              <>
                {long_display}
                {isMobile ? null : short_display}
              </>
            ) : (
              <>
                {short_display}
                {isMobile ? null : long_display}
              </>
            )}
          </Flex>
        </Box>
      );
    }
    const kind = store.config.kind as any;
    const initialSRState =
      store.support === store.config.support &&
      store.resistance === store.config.resistance;
    return (
      <Box>
        <Flex justify="space-between">
          <HStack gap={10}>
            <Box
              pt={5}
              color={store.config.kind === 'long' ? 'blue.700' : 'red.700'}
            >
              Avg: {store.total_size_from_entry}
            </Box>
            <Box
              pt={5}
              color={store.config.kind === 'long' ? 'blue.700' : 'red.700'}
            >
              Reduce Size: {store.quantity_to_sell}
              {/* Size: {store.total_size_from_entry} */}
            </Box>
          </HStack>
          {displayControls && (
            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
            >
              <DropdownMenu
                options={[
                  { label: 'Update Support/Resistance', value: 'update_sr' },
                  { label: 'Reset Support/Resistance', value: 'reset_sr' },
                  { label: 'Update maximum size', value: 'update_max_size' },
                  { label: 'Reset maximum size', value: 'reset_max_size' },
                  { label: 'Remote generation', value: 'remote_generation' },
                  { label: 'Save trades', value: 'save_trades' },
                ].filter((r) => {
                  if (['update_sr', 'reset_sr'].includes(r.value)) {
                    if (initialSRState) {
                      return r.value !== 'reset_sr';
                    }
                    return r.value !== 'update_sr';
                  }
                  return true;
                })}
                colorScheme={initialSRState ? 'green' : 'red'}
                label={`Actions to take`}
                transform={(x) => x.label}
                isLoading={actionLoading}
                onClick={async (option) => {
                  if (option.value === 'update_sr') {
                    store.updateSupportResistance();
                  }
                  if (option.value === 'reset_sr') {
                    store.resetSupportResistance();
                  }
                  if (option.value === 'update_max_size') {
                    try {
                      setActionLoading(true);
                      await store.onUpdateMaximumSize();
                      setActionLoading(false);
                    } catch (error) {
                      setActionLoading(false);
                      onError(error);
                    }
                  }
                  if (option.value === 'reset_max_size') {
                    store.config.updateFields({
                      max_size: store.max_size,
                    });
                  }
                  if (option.value === 'remote_generation') {
                    try {
                      setActionLoading(true);
                      await store.generateRemoteConfig();
                      setActionLoading(false);
                    } catch (error) {
                      setActionLoading(false);
                      onError(error);
                    }
                  }
                  if (option.value === 'save_trades') {
                    try {
                      if (store.trades_with_avg.length > 0) {
                        setActionLoading(true);
                        await store.saveTradesSection();
                        setActionLoading(false);
                      }
                    } catch (error) {
                      setActionLoading(false);
                      onError(error);
                    }
                  }
                }}
              />
              <DropdownMenu
                options={[
                  { label: 'By Quantity', value: 'quantity' },
                  { label: 'By entry', value: 'entry' },
                  { label: 'By stop', value: 'stop' },
                ]}
                label="Determine best risk-reward"
                transform={(x) => x.label}
                colorScheme="blue"
                isDisabled={
                  !Boolean(store.config.long_zones && store.config.short_zones)
                }
                isLoading={isEntryLoading}
                onClick={(config) => {
                  setIsEntryLoading(true);
                  store
                    .determineOptimumRiskReward(config.value, true)
                    .then((result) => {
                      setIsEntryLoading(false);
                    })
                    .catch((error) => {
                      setIsEntryLoading(false);
                    });
                }}
              />

              <Button
                isLoading={isEntryLoading}
                isDisabled={
                  !Boolean(store.config.long_zones && store.config.short_zones)
                }
                onClick={() => {
                  setIsEntryLoading(true);
                  store
                    .determineClosestRisk()
                    .then(() => {
                      setIsEntryLoading(false);
                    })
                    .catch((error) => {
                      setIsEntryLoading(false);
                    });
                  // store.determineOptimumRiskReward('entry').then((result) => {
                  //   alert(`Optimum Risk Reward: ${result}`);
                  // });
                }}
                colorScheme="orange"
              >
                Determine best entry
              </Button>
            </Stack>
          )}
        </Flex>
        <TableContent
          key={`${store.config.entry}-${store.config.stop}`}
          data={store.trades_with_avg}
          store={store}
          kind={kind}
          _columns={
            store.config.mode === 'controlled'
              ? controlledColums
              : columns.filter((i) => {
                if (store.config.currentEntry) {
                  return !['risk', 'incurred'].includes(i.accessor);
                }
                return i;
              })
          }
          onUpdateTakeProfit={onUpdateTakeProfit}
          onTrade={(trade) => {
            setLoading(true);
            return store
              .placeSingleOrder(trade, store.config.kind)
              .then(onSuccess)
              .catch(onError);
          }}
          onCreate={async (trade) => {
            // if (!currentPrice) {
            if (store.config.mode === 'controlled') {
              store.switchModeToDefault(trade);
            } else {
              const price = trade.entry;
              const kind = store.config.kind === 'long' ? 'short' : 'long';
              setNewKind(kind);
            }
          }}
        />
      </Box>
    );
  },
);

const TightFutureTraderAnalyzer: React.FC<{
  store: IFutureInstance;
  accounts?: string[];
  account?: IAccount;
  displayForm?: boolean;
  positionStore?: IPositionStore; // we would be using the positions for the account
  callback?: () => any;
}> = ({
  store,
  accounts = [],
  callback,
  positionStore,
  displayForm = true,
}) => {
    let [loading, setLoading] = React.useState(false);
    let toast = useToast();
    const [currentPrice, setCurrentPrice] = React.useState(0);
    const [currentPrice2, setCurrentPrice2] = React.useState(0);
    const [newKind, setNewKind] = React.useState(store.config.kind);
    React.useEffect(() => {
      // this starts the binance sockets and gets the current price.
      if (positionStore) {
        positionStore.initializeSockets();
        return function cleanup() {
          positionStore.destroySockets();
        };
      }
    }, [positionStore?.currentPrice === 0]);
    if (positionStore && positionStore.currentPrice > 0) {
      const zones = store.activeTradingZone(positionStore.currentPrice);
      console.log('currentZone', zones);
    }
    let _accounts = accounts.filter(Boolean);
    function fc() {
      store.setAccounts(_accounts);
    }
    React.useEffect(() => {
      fc();
    }, []);
    React.useEffect(() => {
      if (callback) {
        callback();
      }
      // store.switchModeToStrategy();
    }, [callback]);
    function swap() {
      const {
        kind: _kind,
        mode: _mode,
        support: _support,
        resistance: _resistance,
      } = store.config;

      // if (positionStore?.longPosition && _kind === 'long') {
      //   store.config.updateFields({
      //     entry: positionStore?.longPosition?.entryPrice,
      //     currentEntry: positionStore?.longPosition?.entryPrice,
      //     currentQty: positionStore?.longPosition?.quantity,
      //   });
      // }
      // if (positionStore?.shortPosition && _kind === 'short') {
      //   store.config.updateFields({
      //     entry: positionStore?.shortPosition?.entryPrice,
      //     currentEntry: positionStore?.shortPosition?.entryPrice,
      //     currentQty: positionStore?.shortPosition?.quantity,
      //   });
      // }
      // if (_mode !== 'main') {
      //   store.config.updateFields({
      //     support: store.support,
      //     resistance: store.resistance,
      //   });
      // }
    }

    function onSuccess() {
      setLoading(false);
      toast({
        title: 'Success',
        description: 'Request placed',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
    function onError(error: any) {
      setLoading(false);
      console.log(error);
      toast({
        title: 'Error',
        description: 'Error placing order',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    function onUpdateTakeProfit(trade: ITrade, key?: string) {
      if (key === 'stop') {
        store.config.updateFields({
          take_profit: trade.stop,
        });
      }
      if (key === 'entry') {
        store.config.updateFields({
          stop: trade.entry,
        });
        if (store.as_select) {
          store.toggleField();
        }
      }
    }
    return (
      <Box>
        {displayForm && (
          <TableActions
            hideFields={
              store.config.mode === 'main'
                ? [
                  // 'kind',
                  'entry',
                  'stop',
                  'tradeSplit',
                  // 'take_profit',
                  'market_type',
                  'budget',
                  'qtyPerTrade',
                  'fee',
                  'currentQty',
                  'support',
                  'resistance',
                ]
                : []
            }
            config={store.config}
            onChange={(k, v) => {
              if (k == 'kind' || k == 'mode') {
                swap();
              }
              // if (k === 'long_zones') {
              //   store.config.updateFields({
              //     entry: parseFloat(v[k]),
              //   });
              // }
              // if (k === 'short_zones') {
              //   store.config.updateFields({
              //     stop: parseFloat(v[k]),
              //   });
              // }
            }}
          />
        )}
        <Box>
          <DisplayTableContent store={store} displayControls={displayForm} />
        </Box>
        {store.config.mode === 'main' ? null : (
          <TablePagination
            loading={loading}
            labels={store.labels() as any}
            store={store}
            onPlaceOrder={(value: any) => {
              setLoading(true);
              store.placeOrder(value).then(onSuccess).catch(onError);
            }}
            hideMargin={!displayForm}
            subAccounts={accounts}
            onRiskChange={(value) => {
              store.updateRiskValue(value, store.trades as any);
            }}
          />
        )}
        {currentPrice ? (
          <>
            <TableContent
              kind={newKind as any}
              data={store.buildTrades(
                store.buildTrades({
                  currentPrice: currentPrice,
                  _kind: newKind as any,
                }),
              )}
              onUpdateTakeProfit={onUpdateTakeProfit}
              onTrade={(trade) => {
                setLoading(true);
                return store
                  .placeSingleOrder(trade, newKind)
                  .then(onSuccess)
                  .catch(onError);
              }}
              onCreate={async (trade) => {
                // setNewKind(store.config.kind);
                setCurrentPrice2(trade.entry);
              }}
            />
            <TablePagination
              loading={loading}
              labels={
                store.labels(
                  store.buildTrades({
                    currentPrice,
                    take_profit: store.trades[0]?.stop,
                  }),
                ) as any
              }
              store={store}
              hideMargin
              onPlaceOrder={(value: any) => {
                setLoading(true);
                const take_profit = store.trades[0]?.stop;
                store
                  .placeOrder(
                    value,
                    store.buildTrades({ currentPrice, take_profit }),
                  )
                  .then(onSuccess)
                  .catch(onError);
              }}
              subAccounts={accounts}
              onRiskChange={(value) => {
                store.updateRiskValue(
                  value,
                  store.buildTrades({
                    currentPrice,
                    take_profit: store.trades[0]?.stop,
                  }),
                );
              }}
            />
          </>
        ) : null}
        {currentPrice2 ? (
          <>
            <TableContent
              kind={store.config.kind as any}
              data={store.buildTrades({
                currentPrice,
                _kind: store.config.kind as any,
              })}
              onUpdateTakeProfit={onUpdateTakeProfit}
              onCreate={async (trade) => {
                // setNewKind(store.config.kind);
                // setCurrentPrice(trade.entry);
              }}
            />
            <TablePagination
              loading={loading}
              labels={
                store.labels(
                  store.buildTrades({
                    currentPrice,
                    _kind: store.config.kind as any,
                  }),
                  undefined,
                  store.config.kind as any,
                ) as any
              }
              store={store}
              onRiskChange={(value) => {
                store.updateRiskValue(
                  value,
                  store.buildTrades({
                    currentPrice,
                    _kind: store.config.kind as any,
                  }),
                );
              }}
              onPlaceOrder={(value: any) => {
                setLoading(true);
                store
                  .placeOrder(
                    value,
                    store.buildTrades({
                      currentPrice,
                      _kind: store.config.kind as any,
                    }),
                    store.config.kind,
                  )
                  .then(onSuccess)
                  .catch(onError);
              }}
              subAccounts={accounts}
            />
          </>
        ) : null}
      </Box>
    );
  };

export default observer(TightFutureTraderAnalyzer);
