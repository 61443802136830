import {
  to_f,
  determine_position_size,
  groupIntoPairs,
  determine_average_entry_and_size,
  groupIntoPairsWithSumLessThan,
} from './utils';

function determine_close_price({
  entry,
  pnl,
  quantity,
  leverage = 1,
  kind = 'long',
}: {
  entry: number;
  pnl: number;
  quantity: number;
  leverage?: number;
  kind?: 'long' | 'short';
}) {
  const dollar_value = entry / leverage;
  const position = dollar_value * quantity;
  if (position) {
    const percent = pnl / position;
    const difference = (position * percent) / quantity;
    const result = kind === 'long' ? difference + entry : entry - difference;
    return result;
  }
  return 0;
}

export function determine_pnl(
  entry: number,
  close_price: number,
  quantity: number,
  kind: string = 'long',
  contract_size?: number,
) {
  if (contract_size) {
    const direction = kind === 'long' ? 1 : -1;
    return quantity * contract_size * direction * (1 / entry - 1 / close_price);
  }

  const difference =
    kind === 'long' ? close_price - entry : entry - close_price;
  return difference * quantity;
}

function* _get_zones({
  current_price,
  focus,
  percent_change,
  places = '%.5f',
}: {
  current_price: number;
  focus: number;
  percent_change: number;
  places?: string;
}): Iterable<number> {
  let last = focus;
  let focus_high = last * (1 + percent_change);
  let focus_low = last * Math.pow(1 + percent_change, -1);

  if (focus_high > current_price) {
    while (focus_high > current_price) {
      yield to_f(last, places);
      focus_high = last;
      last = focus_high * Math.pow(1 + percent_change, -1);
      focus_low = last * Math.pow(1 + percent_change, -1);
    }
  } else {
    if (focus_high <= current_price) {
      while (focus_high <= current_price) {
        yield to_f(focus_high, places);
        focus_low = focus_high;
        last = focus_low * (1 + percent_change);
        focus_high = last * (1 + percent_change);
      }
    } else {
      while (focus_low <= current_price) {
        yield to_f(focus_high, places);
        focus_low = focus_high;
        last = focus_low * (1 + percent_change);
        focus_high = last * (1 + percent_change);
      }
    }
  }
}
export type SignalConfigType = {
  focus: number;
  budget: number;
  percent_change?: number;
  price_places?: string;
  decimal_places?: string;
  zone_risk?: number;
  fee?: number;
  support?: number;
  risk_reward?: number;
  resistance?: number;
  risk_per_trade?: number;
  increase_size?: boolean;
  additional_increase?: number;
  minimum_pnl?: number;
  take_profit?: number;
  increase_position?: boolean;
  minimum_size?: number;
  first_order_size?: number;
};
export class Signal {
  focus: number;
  budget: number;
  percent_change: number = 0.02;
  price_places = '%.5f';
  decimal_places = '%.0f';
  zone_risk: number = 1;
  fee = 0.08 / 100;
  support?: number;
  risk_reward = 4;
  resistance?: number;
  risk_per_trade?: number;
  increase_size = false;
  additional_increase = 0;
  minimum_pnl = 0;
  take_profit?: number;
  increase_position = false;
  minimum_size: any;
  first_order_size: number;

  constructor({
    focus,
    budget,
    percent_change = 0.02,
    price_places = '%.5f',
    decimal_places = '%.0f',
    zone_risk = 1,
    fee = 0.06 / 100,
    support,
    risk_reward = 4,
    resistance,
    risk_per_trade,
    increase_size = false,
    additional_increase = 0,
    minimum_pnl = 0,
    take_profit,
    increase_position = false,
    minimum_size = 0,
    first_order_size = 0,
  }: SignalConfigType) {
    this.minimum_size = minimum_size;
    this.first_order_size = first_order_size;
    this.focus = focus;
    this.budget = budget;
    this.percent_change = percent_change;
    this.price_places = price_places;
    this.decimal_places = decimal_places;
    this.zone_risk = zone_risk;
    this.fee = fee;
    this.support = support;
    this.risk_reward = risk_reward;
    this.resistance = resistance;
    this.risk_per_trade = risk_per_trade;
    this.increase_size = increase_size;
    this.additional_increase = additional_increase;
    this.minimum_pnl = minimum_pnl;
    this.take_profit = take_profit;
    this.increase_position = increase_position;
  }
  build_entry({
    current_price,
    stop_loss,
    pnl,
    stop_percent,
    kind = 'long',
    risk,
    no_of_trades = 1,
    take_profit,
  }: {
    take_profit?: number;
    no_of_trades?: number;
    current_price: number;
    stop_loss?: number;
    kind?: 'long' | 'short';
    risk: number;
    stop_percent?: number;
    pnl?: number;
  }) {
    let _stop_loss = stop_loss;
    if (!_stop_loss && stop_percent) {
      _stop_loss =
        kind === 'long'
          ? current_price * Math.pow(1 + stop_percent, -1)
          : current_price * Math.pow(1 + stop_percent, 1);
    }
    const percent_change = _stop_loss
      ? Math.max(current_price, _stop_loss) /
          Math.min(current_price, _stop_loss) -
        1
      : this.percent_change;
    const _no_of_trades = no_of_trades || this.risk_reward;
    const derivedConfig = {
      ...this,
      percent_change,
      focus: current_price,
      resistance: current_price * Math.pow(1 + percent_change, 5),
      risk_per_trade: risk / this.risk_reward,
      minimum_pnl: pnl,
      risk_reward: _no_of_trades,
      take_profit: take_profit || this.take_profit,
      support: kind === 'long' ? _stop_loss : this.support,
    };
    const instance = new Signal(derivedConfig);
    const ll = this.resistance?.toString().split('.')[0].length || 5;
    if (current_price.toString().length < ll) {
      return [];
    }
    // console.log(
    //   'zones',
    //   instance.get_future_zones({ current_price, kind, raw: true }),
    // );
    let result = instance.get_bulk_trade_zones({ current_price, kind });
    // console.log('result', result);
    // console.log('current_price', current_price, 'kind', kind);
    return result?.filter((x) => {
      if (kind === 'long') {
        return x.entry > x.stop + 0.5;
      }
      return x.entry + 0.5 < x.stop;
    });
  }
  get risk() {
    return this.budget * this.percent_change;
  }

  get min_trades() {
    return parseInt(this.risk.toString());
  }
  get min_price(): number {
    const number: string = this.price_places.replace('%.', '').replace('f', '');
    return 1 * Math.pow(10, -parseInt(number));
  }
  build_opposite_order({
    current_price,
    kind = 'long',
  }: {
    current_price: number;
    kind?: 'long' | 'short';
  }) {
    let _current_price = current_price;
    if (kind === 'long') {
      _current_price = current_price * Math.pow(1 + this.percent_change, -1);
    }
    const result = this.special_build_orders({
      current_price: _current_price,
      kind,
    });
    const first_price = result[result.length - 1].entry;
    const stop = result[0].stop;
    const instance = new Signal({ ...this, take_profit: stop });
    const new_kind = kind === 'long' ? 'short' : 'long';
    return instance.build_orders({
      current_price: first_price,
      kind: new_kind,
    });
  }
  special_build_orders({
    current_price,
    kind = 'long',
  }: {
    current_price: number;
    kind?: 'long' | 'short';
  }) {
    let orders = this.build_orders({ current_price, kind });
    // console.log(
    //   'orders',
    //   orders.map((i: any) => i.entry),
    // );
    // get the middle order
    // let new_orders:any[] = []
    if (orders?.length > 1) {
      orders = this.build_orders({ current_price: orders[1].entry, kind });
    }
    if (orders.length > 0) {
      const new_kind = kind === 'long' ? 'short' : 'long';
      let opposite_order = this.build_orders({
        current_price: orders[orders.length - 1].entry,
        kind: new_kind,
      });
      this.take_profit = opposite_order[0].stop;
      orders = this.build_orders({
        current_price: orders[orders.length - 1].entry,
        kind,
      });
    }
    return orders;
  }
  build_orders({
    current_price,
    kind = 'long',
    limit = false,
    replace_focus = false,
    max_index = 0,
    min_index = 2,
  }: {
    current_price: number;
    kind?: 'long' | 'short';
    limit?: boolean;
    replace_focus?: boolean;
    max_index?: number;
    min_index?: number;
  }) {
    const focus = this.focus;

    if (replace_focus) {
      this.focus = current_price;
    }
    const new_kind = kind === 'long' ? 'short' : 'long';
    const take_profit = this.take_profit;
    this.take_profit = undefined;
    let result = this.get_bulk_trade_zones({
      current_price,
      kind: new_kind,
      limit,
    });
    // console.log({ result });
    if (result?.length) {
      let oppositeStop = result[0]['sell_price'];
      let oppositeEntry = result[result.length - 1]['entry'];
      let tradeLength = this.risk_reward + 1;
      let percentChange =
        Math.abs(
          1 -
            Math.max(oppositeEntry, oppositeStop) /
              Math.min(oppositeEntry, oppositeStop),
        ) / tradeLength;
      let newTrades = [];
      for (let x = 0; x < tradeLength; x++) {
        newTrades.push(oppositeStop * Math.pow(1 + percentChange, x));
      }
      if (kind === 'short') {
        newTrades = [];
        for (let x = 0; x < tradeLength; x++) {
          newTrades.push(oppositeStop * Math.pow(1 + percentChange, x * -1));
        }
      }
      this.take_profit = take_profit;
      newTrades = newTrades.map((r) => this.to_f(r));
      // console.log({newTrades, kind,percentChange})
      if (kind === 'long') {
        if (newTrades[1] > current_price) {
          const start = newTrades[0];
          newTrades = [];
          for (let x = 0; x < tradeLength; x++) {
            newTrades.push(start * Math.pow(1 + percentChange, x * -1));
          }
          newTrades.sort();
        }
      }
      const newR = this.process_orders({
        current_price,
        stop_loss: newTrades[0],
        trade_zones: newTrades,
        kind,
      });
      // console.log({
      //   newR,
      //   current_price,
      //   stop_loss: newTrades[0],
      //   trade_zones: newTrades,
      //   kind,
      // });
      return newR;
    }
    this.focus = focus;

    return result;
  }

  build_orders_old({
    current_price,
    kind = 'long',
    limit = false,
    replace_focus = false,
    max_index = 0,
    min_index = 2,
  }: {
    current_price: number;
    kind?: 'long' | 'short';
    limit?: boolean;
    replace_focus?: boolean;
    max_index?: number;
    min_index?: number;
  }) {
    const focus = this.focus;

    if (replace_focus) {
      this.focus = current_price;
    }

    const result = this.get_bulk_trade_zones({ current_price, kind, limit });
    if (result?.length) {
      let next_focus;
      if (kind == 'long') {
        next_focus = current_price * (1 + this.percent_change);
      } else {
        next_focus = current_price * Math.pow(1 + this.percent_change, -1);
      }
      let new_result = this.get_bulk_trade_zones({
        current_price: next_focus,
        kind,
        limit,
      });
      if (new_result?.length) {
        for (let i of result) {
          let condition =
            kind === 'long'
              ? (a: any, b: any) => a >= b
              : (a: any, b: any) => a <= b;
          let potentials = new_result
            .filter((x: any) => condition(x['entry'], i['risk_sell']))
            .map((x: any) => x['entry']);
          if (potentials.length && max_index) {
            if (kind === 'long') {
              console.log('slice: ', potentials.slice(0, max_index));
              i['risk_sell'] = Math.max(...potentials.slice(0, max_index));
            } else {
              i['risk_sell'] = Math.min(...potentials.slice(0, max_index));
            }
            // let func = kind === 'long' ? Math.min : Math.max;
            // i['risk_sell'] = func(...potentials);
            // i['risk_sell'] = determine_close_price({
            //   entry: i['entry'],
            //   pnl: i['incurred'] * 2,
            //   quantity: i['quantity'],
            //   kind: kind,
            // });
            i['pnl'] = this.to_df(
              determine_pnl(i['entry'], i['risk_sell'], i['quantity'], kind),
            );
          }
        }
      }
    }
    this.focus = focus;

    return result;
  }
  get_bulk_trade_zones({
    current_price,
    kind = 'long',
    limit = false,
  }: {
    current_price: number;
    kind?: 'long' | 'short';
    limit?: boolean;
  }): any {
    const futures = this.get_future_zones({ current_price, kind });
    // if (kind === 'short') {
    //   console.log('zones', futures, 'price', current_price);
    // }
    const original = this.zone_risk;
    if (futures) {
      const values = futures;
      if (values) {
        let trade_zones = values.sort();

        if (this.resistance) {
          trade_zones = trade_zones.filter((x) =>
            this.resistance ? x <= this.resistance : true,
          );
          if (kind === 'short') {
            trade_zones = trade_zones.sort((a, b) => b - a);
          }
        }
        if (trade_zones.length > 0) {
          const stop_loss = trade_zones[0];
          const result = this.process_orders({
            current_price,
            stop_loss,
            trade_zones,
            kind,
          });
          // console.log({result,futures})
          if (!result.length) {
            if (kind === 'long') {
              let m_z = this.get_margin_range(futures[0]);
              if (m_z && m_z[0] < current_price && current_price !== m_z[1]) {
                // console.log('m_z', m_z, 'current', current_price);
                return this.get_bulk_trade_zones({
                  current_price: m_z[1],
                  kind,
                  limit,
                });
              }
            }
          }

          this.zone_risk = original;
          return result;
        }
      }
    }

    this.zone_risk = original;
  }

  get_future_zones({
    current_price,
    kind = 'long',
    raw,
  }: {
    raw?: boolean;
    current_price: number;
    kind?: 'long' | 'short';
  }): number[] {
    if (raw) {
    }
    const margin_range = this.get_margin_range(current_price, kind);
    let margin_zones = this.get_margin_zones({ current_price });
    let remaining_zones = margin_zones.filter(
      (x) => JSON.stringify(x) != JSON.stringify(margin_range),
    );
    // console.log({ margin_range, margin_zones });
    if (margin_range) {
      const difference = Math.abs(margin_range[0] - margin_range[1]);
      const spread = to_f(difference / this.risk_reward, this.price_places);
      let entries: number[];
      const percent_change = this.percent_change / this.risk_reward;

      if (kind === 'long') {
        entries = Array.from(
          { length: Math.floor(this.risk_reward) + 1 },
          (_, x) => to_f(margin_range[1] - spread * x, this.price_places),
        );
      } else {
        // entries# = Array.from(
        //   { length: Math.floor(this.risk_reward) + 1 },
        //   (_, x) => to_f(margin_range[1] + spread * x, this.price_places),
        // );
        entries = Array.from(
          { length: Math.floor(this.risk_reward) + 1 },
          (_, x) =>
            to_f(
              margin_range[1] * Math.pow(1 + percent_change, x),
              this.price_places,
            ),
        );
      }
      if (
        Math.min(...entries) < this.to_f(current_price) &&
        this.to_f(current_price) < Math.max(...entries)
      ) {
        console.log('found: ', entries, 'current: ', current_price);
        return entries.sort((a, b) => a - b);
      }
      // console.log({ remaining_zones, margin_range, entries });
      if (remaining_zones.length > 0) {
        let new_range = remaining_zones[0];
        let entries = [];
        let x = 0;
        if (new_range) {
          while (entries.length < this.risk_reward + 1) {
            if (kind === 'long') {
              let value = this.to_f(new_range[1] - spread * x);
              if (value <= current_price) {
                entries.push(value);
              }
            } else {
              let value = this.to_f(
                new_range[1] * Math.pow(1 + percent_change, x),
              );
              // console.log({ x, value });
              if (value >= current_price) {
                entries.push(value);
              }
            }
            x += 1;
          }
        }
        return entries.sort((a, b) => a - b);
      }
      // console.log({remaining_zones,entries})
      if (
        remaining_zones.length === 0 &&
        this.to_f(current_price) <= Math.min(...entries)
      ) {
        const next_focus =
          margin_range[0] * Math.pow(1 + this.percent_change, -1);
        let entries = [];
        let x = 0;
        while (entries.length < this.risk_reward + 1) {
          if (kind === 'long') {
            let value = this.to_f(next_focus - spread * x);
            if (value <= this.to_f(current_price)) {
              entries.push(value);
            }
          } else {
            let value = this.to_f(next_focus * Math.pow(1 + percent_change, x));
            // console.log({ x, value });
            if (value >= this.to_f(current_price)) {
              entries.push(value);
            }
          }
          x += 1;
        }

        return entries.sort((a, b) => a - b);
      }
      return entries.sort((a, b) => a - b);
    }

    return [];
  }
  to_f(value: number, places?: string) {
    return to_f(value, places || this.price_places);
  }
  get_margin_zones({
    current_price,
    kind = 'long',
  }: {
    current_price: number;
    kind?: 'long' | 'short';
  }) {
    if (this.support && kind === 'long') {
      let result = [];
      let start = current_price;
      let counter = 0;
      while (start > this.support) {
        let v = this.get_margin_range(start);
        if (v) {
          result.push(v);
          start = v[0] - this.min_price;
          counter += 1;
        }
        if (counter > 10) {
          break;
        }
      }
      return result;
    }
    if (this.resistance) {
      let result = [];
      let start = current_price;
      let counter = 0;
      while (start < this.resistance) {
        let v = this.get_margin_range(start);
        if (v) {
          result.push(v);
          start = v[1] + this.min_price;
        }
        if (counter > 10) {
          break;
        }
      }
      return result;
    }
    return [this.get_margin_range(current_price)];
  }

  get_margin_range(current_price: number, kind = 'long') {
    // const diff = kind === 'long' ? -this.min_price : this.min_price;
    const diff = -this.min_price;
    const zones =
      _get_zones({
        current_price: current_price + diff,
        focus: this.focus,
        percent_change: this.percent_change,
        places: this.price_places,
      }) || [];
    // const top_zones = Array.from(zones);

    const top_zones = [];
    for (const i of zones) {
      top_zones.push(this.to_f(i));
    }
    // console.log('zones', top_zones);
    if (top_zones.length > 0) {
      const result: number = top_zones[top_zones.length - 1];
      return [this.to_f(result), this.to_f(result * (1 + this.percent_change))];
    }

    return null;
  }

  process_orders({
    current_price,
    stop_loss,
    trade_zones,
    kind = 'long',
  }: {
    current_price: number;
    stop_loss: number;
    trade_zones: number[];
    kind?: 'long' | 'short';
  }): any[] {
    const number_of_orders = trade_zones.slice(1).length;
    let take_profit = stop_loss * (1 + 2 * this.percent_change);
    if (kind === 'short') {
      take_profit = stop_loss * Math.pow(1 + 2 * this.percent_change, -1);
    }
    if (this.take_profit) {
      take_profit = this.take_profit;
    }
    if (number_of_orders > 0) {
      const risk_per_trade = this.get_risk_per_trade(number_of_orders);
      let limit_orders = trade_zones
        .slice(1)
        .filter((x) => x <= this.to_f(current_price));
      let market_orders = trade_zones
        .slice(1)
        .filter((x) => x > this.to_f(current_price));

      if (kind === 'short') {
        limit_orders = trade_zones
          .slice(1)
          .filter((x) => x >= this.to_f(current_price));
        market_orders = trade_zones
          .slice(1)
          .filter((x) => x < this.to_f(current_price));
      }

      // console.log({ limit_orders, market_orders });
      const increase_position = Boolean(this.support) && this.increase_position;
      const market_trades =
        limit_orders.length > 0
          ? market_orders
              .map((x, i) => {
                const defaultStopLoss =
                  i === 0
                    ? limit_orders[limit_orders.length - 1]
                    : market_orders[i - 1];
                const y = this.build_trade_dict({
                  entry: x,
                  stop: increase_position
                    ? (this.support as number)
                    : defaultStopLoss,
                  risk: risk_per_trade,
                  arr: market_orders,
                  index: i,
                  kind,
                  start: market_orders.length + limit_orders.length,
                  take_profit,
                });

                return y;
              })
              .filter((y) => y)
          : [];

      let total_incurred_market_fees = 0;

      if (market_trades.length > 0) {
        let first = market_trades[0];
        if (first) {
          total_incurred_market_fees += first.incurred;
          total_incurred_market_fees += first.fee;
        }
      }

      const limit_trades = (
        limit_orders.map((x, i) => {
          const defaultStopLoss = i === 0 ? stop_loss : limit_orders[i - 1];
          const new_stop = kind === 'long' ? this.support : stop_loss;
          const y = this.build_trade_dict({
            entry: x,
            stop:
              (this.increase_position ? new_stop : defaultStopLoss) ||
              defaultStopLoss,
            risk: risk_per_trade,
            arr: limit_orders,
            index: i,
            new_fees: total_incurred_market_fees,
            kind,
            start: market_orders.length + limit_orders.length,
            take_profit,
          });

          return y !== null ? y : undefined;
        }) || []
      ).filter((y) => y !== undefined);

      let total_orders = limit_trades.concat(market_trades);
      if (this.minimum_size) {
        let greater_than_min_size = total_orders.filter(
          (o) => o.quantity >= this.minimum_size,
        );
        let less_than_min_size = total_orders.filter(
          (o) => o.quantity < this.minimum_size,
        );
        // group less_than_min_size into pairs
        less_than_min_size = groupIntoPairsWithSumLessThan(
          less_than_min_size,
          this.minimum_size,
          'quantity',
          this.first_order_size,
        );

        less_than_min_size = less_than_min_size.map((q) => {
          let avg_entry = determine_average_entry_and_size(
            q.map((o) => ({
              price: o.entry,
              quantity: o.quantity,
            })),
            this.decimal_places,
            this.price_places,
          );
          let candidate = q[0];
          candidate.entry = avg_entry.price;
          candidate.quantity = avg_entry.quantity;
          return candidate;
        });
        return greater_than_min_size.concat(less_than_min_size);
      }
      return total_orders;
    }

    return [];
  }
  get_risk_per_trade(number_of_orders: number): number {
    if (this.risk_per_trade) {
      return this.risk_per_trade;
    }
    return this.zone_risk / number_of_orders;
  }
  build_trade_dict({
    entry,
    stop,
    risk,
    arr,
    index,
    new_fees = 0,
    kind = 'long',
    start = 0,
    take_profit,
  }: {
    entry: number;
    stop: number;
    risk: number;
    arr: number[];
    index: number;
    new_fees?: number;
    kind?: 'long' | 'short';
    start?: number;
    take_profit?: number;
  }) {
    // const considered = arr.flatMap((_, i) => (i > index ? [i] : []));
    const considered = arr.map((x, i) => i).filter((i) => i > index);

    const with_quantity = considered
      .map((x) => {
        const q = determine_position_size({
          entry: arr[x],
          stop: arr[x - 1],
          budget: risk,
          places: this.decimal_places,
        });
        if (!q) {
          return undefined;
        }
        if (this.minimum_size) {
          if (q < this.minimum_size) {
            return undefined;
          }
        }
        return { quantity: q, entry: arr[x] };
      })
      .filter((x) => x);

    if (this.increase_size) {
      const arr_length = with_quantity.length;
      with_quantity.forEach((x, i) => {
        if (x) {
          x.quantity = x.quantity * (arr_length - i);
        }
      });
    }

    const fees = with_quantity.map((x: any) => {
      // if (x) {
      return this.to_df(this.fee * x.quantity * x.entry);
      // }
    });
    const previous_risks = with_quantity.map((x: any) => {
      return this.to_df(risk);
    });

    const multiplier = start - index;
    const incurred_fees =
      fees.reduce((a, b) => a + b, 0) +
      previous_risks.reduce((a, b) => a + b, 0);

    const lost_risk = fees.length * risk;

    let quantity = determine_position_size({
      entry,
      stop,
      budget: risk,
      places: this.decimal_places,
    });
    if (!quantity) {
      return undefined;
    }

    if (this.increase_size) {
      quantity = quantity * multiplier;
      const new_risk = determine_pnl(entry, stop, quantity, kind);
      risk = Math.abs(new_risk);
    }

    const fee = this.to_df(this.fee * quantity * entry);
    const increment = Math.abs(arr.length - (index + 1));
    let pnl = this.to_df(risk) * (this.risk_reward + increment);
    // console.log('pnl', pnl, 'risk_reward', this.risk_reward);
    // pnl = this.to_df(pnl + new_fees + incurred_fees + lost_risk + fee);
    // pnl = pnl < this.additional_increase ? pnl + this.additional_increase : pnl;
    // pnl = pnl < this.minimum_pnl ? this.minimum_pnl : pnl;
    if (this.minimum_pnl) {
      pnl = this.minimum_pnl + fee;
    }

    let sell_price = determine_close_price({ entry, pnl, quantity, kind });
    if (take_profit && !this.minimum_pnl) {
      sell_price = take_profit;
      pnl = this.to_df(determine_pnl(entry, sell_price, quantity, kind));
      // add fees to pnl and recalculate sell_price
      pnl = pnl + fee;
      sell_price = determine_close_price({ entry, pnl, quantity, kind });
      // console.log('take_profit', take_profit, 'pnl', pnl, 'kind', kind);
    }
    let risk_sell = sell_price;
    // if (incurred_fees > 0) {
    //   risk_sell = determine_close_price({
    //     entry,
    //     pnl: incurred_fees,
    //     quantity,
    //     kind,
    //   });
    // }

    return {
      entry,
      risk: this.to_df(risk),
      quantity,
      sell_price: this.to_f(sell_price),
      risk_sell: this.to_f(risk_sell),
      stop,
      pnl,
      fee,
      net: this.to_df(pnl - fee),
      incurred: this.to_df(incurred_fees + new_fees),
      stop_percent: this.to_df(Math.abs(entry - stop) / entry),
    };
  }

  to_df(currentPrice: number, places: string = '%.3f') {
    return to_f(currentPrice, places);
  }
}
