import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getParent,
  getSnapshot,
  Instance,
  types,
} from 'mobx-state-tree';
import type { BotAdapterType } from '../adapter';
import {
  calculate_maximum_size,
  determine_close_price,
  determine_position_size,
  get_ideal_entry_and_size,
} from './hedge';
import { IJobStore, JobInstance, JobStore } from './jobConfig';
import { AddNewSymbolStore, IOrder, Order } from './oldConfig';
import { ProfileStore } from './profileConfig';
import type { IProfileConfig } from './profileConfig';
import type { ISpotMarketConfig } from './spotConfig';
import { RootStore as MobileRootStore } from '../mobileAppView/stores';
import {
  liquidationAnalysis,
  profitHelper,
  getParamForField,
  allCoins,
  asCoins,
  SpecialCoins,
} from './utils';
import { SpotMarketStore } from './spotConfig';
import { FutureInstance } from './futureConfig';
import { AlertStore } from './alertConfig';
import { BarePositionStore, IBarePositionStore } from './barePosition';
import SolvedTraderConfig from './solvedConfig/rootStore';
export type { IProfileConfig } from './profileConfig';
export type { ISpotMarketConfig } from './spotConfig';
const configs = [
  { name: 'profit', label: 'Profit', kind: 'input' },
  { name: 'other_profit', label: 'Short Profit', kind: 'input' },
  {
    name: 'profit_style',
    label: 'Profit Style',
    kind: 'select',
    options: ['balance', 'slight'],
  },
  { name: 'bias_r', label: 'Long Risk Reward', kind: 'special' },
  { name: 'other_r', label: 'Short Risk Reward', kind: 'special' },
  { name: 'bias_ratio', label: 'Long to short ratio', kind: 'input' },
  { name: 'start_ratio', label: 'Start ratio', kind: 'special' },
  {
    name: 'delay',
    label: 'Additional short buy',
    kind: 'checkbox',
    group: true,
  },
  { name: 'use_maximum', label: 'Use Maximum', kind: 'checkbox', group: true },
  { name: 'spread', label: 'Spread', kind: 'input' },
  { name: 'split', label: 'Split/Percent', kind: 'input' },
  { name: 'starter', label: 'Starter', kind: 'input' },
  { name: 'budget', label: 'Budget', kind: 'input' },
  { name: 'maximum', label: 'Maximum Size', kind: 'input' },
  { name: 'support', label: 'Support Zone', kind: 'input', pair: 'ema' },
  { name: 'resistance', label: 'Resistance', kind: 'input' },
  { name: 'ideal_stop', label: 'Ideal Stop', kind: 'input', pair: 'ideal_ema' },
  { name: 'ema', label: 'Support Ema', kind: 'input' },
  { name: 'ideal_ema', label: 'Ideal stop Ema', kind: 'input' },
  {
    name: 'chart_type',
    label: 'Chart type',
    kind: 'select',
    options: [
      'minutes_15',
      'minutes_30',
      'hours_1',
      'hours_4',
      'daily',
      'weekly',
    ],
  },
  { name: 'minimum_balance', label: 'Minimum Balance', kind: 'input' },
  { name: 'bias', label: 'Bias', kind: 'radio', options: ['long', 'short'] },
  { name: 'bias_only', label: 'Bias only', kind: 'checkbox' },
  { name: 'acceptable_loss', label: 'Afford to loose', kind: 'checkbox' },
  { name: 'sell_spread', label: 'Spread for loss trades', kind: 'input' },
  { name: 'sell_at_entry', kind: 'checkbox', label: 'Sell at entry' },
  { name: 'reduce', kind: 'checkbox', label: 'Reduce mode' },
  { name: 'start_size', kind: 'input', label: 'Start Size' },
  { name: 'fee', kind: 'input', label: 'Fee' },
  { name: 'long_p', kind: 'input', label: 'Long Sell %' },
  { name: 'short_p', kind: 'input', label: 'Short Sell %' },
  { name: 'long_e', kind: 'input', label: 'Long entry' },
  { name: 'short_e', kind: 'input', label: 'Short entry' },
  { name: 'long_s', kind: 'special', label: 'Long size' },
  { name: 'short_s', kind: 'special', label: 'Short size' },
  { name: 'along_e', kind: 'input', label: 'Additional Long entry' },
  { name: 'ashort_e', kind: 'input', label: 'Additional Short entry' },
  { name: 'along_s', kind: 'special', label: 'Additional Long size' },
  { name: 'ashort_s', kind: 'special', label: 'Additional Short size' },
  { name: 'additional_budget', kind: 'special', label: 'Additional budget' },
  { name: 'leverage', kind: 'input', label: 'Leverage' },
  { name: 'u_leverage', kind: 'input', label: 'Size Leverage' },
  { name: 'contract_size', kind: 'input', label: 'Contract size' },
  { name: 'job_id', kind: 'input', label: 'Job ID' },
];

const config_fields = {
  config_1: ['profit', 'other_profit', 'profit_style'],
  config_2: [
    'bias_r',
    'other_r',
    'bias_ratio',
    'start_ratio',
    'profit',
    ['delay', 'use_maximum'],
    'spread',
  ],
  config_3: ['profit', 'split', 'start_ratio', 'starter'],
  config_4: [
    'bias_r',
    'bias_ratio',
    'start_size',
    'reduce',
    'spread',
    'fee',
    'support',
    'resistance',
  ],
  config_5: [
    'bias_r',
    'other_r',
    'long_p',
    'short_p',
    'long_e',
    'short_e',
    'long_s',
    'short_s',
    'additional_budget',
  ],
  config_6: [
    'bias_r',
    'other_r',
    'long_p',
    'short_p',
    'long_e',
    'short_e',
    'long_s',
    'short_s',
    'along_e',
    'ashort_e',
    'along_s',
    'ashort_s',
    'additional_budget',
    'contract_size',
    'leverage',
    'u_leverage',
  ],
  general: [
    'budget',
    'bias',
    'maximum',
    'minimum_balance',
    'minimum_size',
    'chart_type',
    'bias_only',
    ['support', 'ema'],
    ['ideal_stop', 'ideal_ema'],
    ['acceptable_loss', 'sell_spread'],
  ],
};

export const BotConfig = types
  .model('BotConfig', {
    symbol: types.string,
    budget: types.optional(types.number, 0),
    spread: types.optional(types.number, 0),
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
    profit: types.optional(types.number, 0),
    split: types.optional(types.number, 0),
    r: types.optional(types.number, 0),
    bias: types.optional(types.enumeration('Bias', ['long', 'short']), 'long'),
    price_places: types.optional(types.string, '%.2f'),
    maximum: types.optional(types.number, 0),
    decimal_places: types.optional(types.string, '%.3f'),
    interval: types.optional(types.number, 30),
    swap_factor: types.optional(types.number, 0),
    onswap: types.optional(types.string, 'profit'),
    delay: types.optional(types.boolean, false),
    profit_style: types.maybeNull(
      types.enumeration('ProfitStyle', ['balance', 'slight']),
    ),
    minimum_balance: types.optional(types.number, 30),
    chart_type: types.optional(
      types.enumeration('ChartType', [
        'minutes_15',
        'minutes_30',
        'hours_1',
        'hours_4',
        'daily',
        'weekly',
      ]),
      'hours_1',
    ),
    other_profit: types.optional(types.number, 0),
    ideal_stop: types.optional(types.number, 0),
    bias_r: types.optional(types.number, 1),
    bias_ratio: types.optional(types.number, 0.25),
    other_r: types.optional(types.number, 0.12),
    use_maximum: types.optional(types.boolean, false),
    source: types.optional(
      types.enumeration('Source', ['open', 'high', 'low', 'close']),
      'close',
    ),
    strategy: types.maybeNull(
      types.enumeration('Strategy', ['profit_maximizer', 'loss_minimizer']),
    ),
    start_ratio: types.optional(types.number, 1),
    bias_only: types.optional(types.boolean, false),
    follow_trend: types.optional(types.boolean, false),
    starter: types.optional(types.number, 0),
    risk: types.optional(types.number, 0),
    acceptable_loss: types.optional(types.boolean, false),
    sell_spread: types.optional(types.number, 0),
    ema: types.optional(types.number, 0),
    ideal_ema: types.optional(types.number, 0),
    sell_at_entry: types.optional(types.boolean, false),
    fee: types.optional(types.number, 0),
    long_p: types.optional(types.number, 0),
    short_p: types.optional(types.number, 0),
    long_e: types.optional(types.number, 0),
    short_e: types.optional(types.number, 0),
    long_s: types.optional(types.number, 0),
    short_s: types.optional(types.number, 0),
    along_e: types.optional(types.number, 0),
    ashort_e: types.optional(types.number, 0),
    along_s: types.optional(types.number, 0),
    ashort_s: types.optional(types.number, 0),
    minimum_size: types.optional(types.number, 0.001),
    start_size: types.optional(types.number, 0.001),
    reduce: types.optional(types.boolean, false),
    additional_budget: types.optional(types.number, 0),
    contract_size: types.optional(types.number, 100),
    leverage: types.optional(types.number, 125),
    u_leverage: types.optional(types.number, 0),
    job_id: types.optional(types.string, ''),
    jobs: types.optional(types.array(JobStore), []),
    profiles: types.optional(types.array(ProfileStore), []),
    spot_markets: types.optional(types.array(SpotMarketStore), []),
    alerts: types.optional(types.array(AlertStore), []),
    editJobId: types.optional(types.string, ''),
    editAlertId: types.optional(types.number, -1),
    editProfileId: types.optional(types.string, ''),
    newProfile: types.optional(ProfileStore, {}),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => {
    function bySide(side: any) {
      let result = [];
      let to_list = (o: any) => {
        let array = getSnapshot(o);
        return Array.isArray(o) ? array : [getSnapshot(o)];
      };
      result = self.jobs.filter((job) => {
        let actions = job.actions
          .filter((x) => Boolean(x.params))
          .map((u) => to_list(u.params))
          .flat()
          .filter((w: any) => w.kind)
          .map((j: any) => j.kind);
        return actions.includes(side);
      });
      return result;
    }
    function getBaseAsset() {
      let _type = self.symbol.toLowerCase().includes('usdt') ? 'usdt' : 'coin';
      let result =
        _type === 'usdt'
          ? self.symbol.toLowerCase().includes('usdt')
            ? 'USDT'
            : 'BUSD'
          : self.symbol.toUpperCase().split('USD_')[0];
      if (self.symbol.toLowerCase().includes('-')) {
        result = result.split('-')[0];
      }
      if (self.symbol.toLowerCase() == 'usdt-usd') {
        //console.log(result);
      }
      return result;
    }
    return {
      get _type() {
        if (self.symbol.toLowerCase() == 'btcbusd') {
          return 'usdt';
        }
        return self.symbol.toLowerCase().includes('usdt') ? 'usdt' : 'coin';
      },
      get currencyCoin() {
        let result = getBaseAsset();
        return result;
      },
      get appInstance(): IBotStore {
        return this.accountInstance.parent;
      },
      get accountInstance(): IAccount {
        let parent: any = getParent(self, 2);
        return parent;
      },
      get exchangeData() {
        return {
          symbol: self.symbol,
          baseAsset: this.baseAsset,
          actions: [
            'Pause all jobs',
            'Resume all jobs',
            'Delete all jobs',
            'Remove open orders',
          ].map((o) => ({
            label: o,
            value: o,
          })),
          item: [],
        };
      },
      get baseAsset() {
        let result =
          this._type == 'usdt'
            ? self.symbol.split(this.currencyCoin)[0]
            : this.currencyCoin;
        if (result.includes('-')) {
          result = this.currencyCoin;
        }
        if (self.symbol.toLowerCase() == 'btcbusd') {
          result = 'BTC';
        }
        return result;
      },
      get editJob() {
        return self.jobs.find((o) => o.name === self.editJobId);
      },
      get editAlert() {
        return self.editAlertId > -1 ? self.alerts[self.editAlertId] : null;
      },
      get editProfile() {
        return self.profiles.find((o) => o.id === self.editProfileId);
      },
      get pricePlaces() {
        return parseInt(
          self.price_places.replace('%.', '').replace('f', '') || '0',
        );
      },
      get decimalPlaces() {
        return parseInt(
          self.decimal_places.replace('%.', '').replace('f', '') || '0',
        );
      },
      get longJobs() {
        return bySide('long');
      },
      get shortJobs() {
        return bySide('short');
      },
      get otherJobs(): any {
        let longIds = this.longJobs.map((o) => o.job_id);
        let shortIds = this.shortJobs.map((o) => o.job_id);
        let remaining = self.jobs
          .map((o) => o.job_id)
          .filter((o) => [...longIds, ...shortIds].includes(o) === false);
        return remaining.map((o) => self.jobs.find((v) => v.job_id == o));
      },
      get marketPaused() {
        let longPaused =
          this.longJobs.length > 0
            ? this.longJobs.every((o) => o.paused)
            : true;
        let shortPaused =
          this.shortJobs.length > 0
            ? this.shortJobs.every((o) => o.paused)
            : true;
        let otherPaused =
          this.otherJobs.length > 0
            ? this.otherJobs.every((o: any) => o.paused)
            : true;
        //console.log({ longPaused, shortPaused, symbol: self.symbol });
        return longPaused && shortPaused && otherPaused;
      },
      get config_fields() {
        return config_fields;
      },
      get job_ids() {
        return self.jobs.map((o) => o.name);
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        return getParamForField(self, configs, field, isGroup);
      },
      getSize(openTrade: IOrder) {
        let diff = Math.abs(openTrade.entryPrice - self.ideal_stop);
        let size = self.budget / diff;
        const contracts = size * openTrade.entryPrice;
        const stopPercent = self.budget / contracts;
        let extryStop = stopPercent * openTrade.entryPrice * self.bias_r;
        let takeProfit =
          self.bias === 'long'
            ? openTrade.entryPrice + extryStop
            : openTrade.entryPrice - extryStop;
        return { size: size.toFixed(3), takeProfit };
      },
      getSize2(
        openTrade:
          | {
              entryPrice: number;
              quantity: number;
              kind: 'long' | 'short';
            }
          | IOrder,
        contract_size?: number,
        budget?: number,
      ) {
        let _budget = budget || self.budget;
        let pnl =
          openTrade.kind == 'long'
            ? _budget * self.bias_r * self.long_p
            : _budget * self.other_r * self.short_p;
        let quantity =
          openTrade.kind == 'long'
            ? openTrade.quantity * self.long_p
            : openTrade.quantity * self.short_p;
        let close = determine_close_price(
          openTrade.entryPrice,
          pnl,
          quantity,
          openTrade.kind,
          contract_size,
        );
        return { takeProfit: close, pnl, quantity };
      },
      get currentProfile() {
        return this.editProfile || self.newProfile;
      },
      get profileTitle() {
        if (this.editProfile) {
          return `Edit ${this.editProfile.name}`;
        }
        return `Create new profile`;
      },
      getStop(openTrade: IOrder) {
        const contracts = openTrade.quantity * openTrade.entryPrice;
        const stopPercent = self.budget / contracts;
        let extryStop = stopPercent * openTrade.entryPrice;
        let stop =
          openTrade.kind === 'long'
            ? openTrade.entryPrice - extryStop
            : openTrade.entryPrice + extryStop;
        let multiplier = extryStop * self.bias_r;
        let takeProfit =
          openTrade.kind === 'long'
            ? openTrade.entryPrice + multiplier
            : openTrade.entryPrice - multiplier;
        return { stop, takeProfit };
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    function setEditProfile(profile: IProfileConfig | null) {
      self.editProfileId = profile ? profile.id : '';
      if (!profile) {
        self.newProfile.clear();
      }
    }
    const duplicateProfile: any = flow(function* duplicateProfile(
      profile: IProfileConfig,
    ) {
      setEditProfile(null);
      let newProfile: any = {
        ...getSnapshot(profile),
        id: '',
        name: `${profile.name}-Copy`,
      };
      newProfile.id = '';
      self.newProfile = newProfile;
      try {
        let owner = self.accountInstance.owner;
        let symbol = self.symbol;

        self.loading = true;
        yield adapter.createProfile({
          owner,
          symbol,
          profile: self.newProfile.data,
        });
        yield self.appInstance.getBotAccounts();
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    return {
      updateSpotMarkets(new_spot_markets: any[]) {
        self.spot_markets = cast(new_spot_markets);
      },
      duplicateProfile,
      setEditJob(job: any, index: number) {
        if (job.alert) {
          self.editAlertId = index;
        } else {
          self.editJobId = job.name;
        }
      },
      setEditProfile,
      updateEditedProfile(profile: any) {
        self.profiles = cast(
          self.profiles.map((o) => {
            if (o.id === profile.id) {
              return profile;
            }
            return getSnapshot(o);
          }),
        );
      },
      updateEditedJob(job: any) {
        self.jobs = cast(
          self.jobs.map((o) => {
            if (o.displayName === job.name) {
              return job;
            }
            return getSnapshot(o);
          }),
        );
      },
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

const Account = types
  .model('Account', {
    owner: types.identifier,
    config: types.maybeNull(BotConfig),
    configs: types.optional(types.array(BotConfig), []),
    exchanges: types.optional(types.array(types.string), []),
    currentExchange: types.maybe(types.string),
    selectedCoin: types.maybe(types.string),
    loading: types.optional(types.boolean, false),
    selectedMarket: types.optional(types.string, ''),
    joblistFilter: types.optional(types.string, ''),
    futureCTrader: types.optional(FutureInstance, {}),
    solvedTrader: types.optional(SolvedTraderConfig, {}),
  })
  .views((self) => {
    function defaultConfig() {
      return self.config || self.configs[0];
    }
    return {
      get allSpotMarketsAvailable(): string[] {
        if (this.currentCoin) {
          //console.log(this.currentCoin, 'is here');
          //console.log('Current coin is ', this.currentCoin);
          let result = this.parent.supportedSpotMarkets.filter((x) => {
            if (this.currentCoin.toLowerCase() === 'usdt') {
              return x.toLowerCase().includes(this.currentCoin.toLowerCase());
            }
            return x.toLowerCase().startsWith(this.currentCoin.toLowerCase());
          });
          let main_market = this.parent.supportedMarkets.filter((x) => {
            if (this.currentCoin.toLowerCase() === 'usdt') {
              return x.toLowerCase().includes(this.currentCoin.toLowerCase());
            }
            return x.toLowerCase().startsWith(this.currentCoin.toLowerCase());
          });
          return result.concat(main_market);
        }
        return [];
      },
      get spotMarketsToAdd() {
        let result = this.allSpotMarketsAvailable.filter(
          (x) =>
            !this.spot_markets
              .map((o) => o.symbol?.toLowerCase())
              .includes(x.toLowerCase()),
        );
        return result;
        // if (this.currentCoin) {
        //   //console.log('Current coin is ', this.currentCoin);
        //   let result = this.parent.supportedSpotMarkets.filter((x) => {
        //     return x.toLowerCase().startsWith(this.currentCoin.toLowerCase());
        //   });
        //   return result;
        // }
        // return [];
      },
      get spot_markets(): ISpotMarketConfig[] {
        let all_markets: any = self.configs.map((o) => o.spot_markets);
        return all_markets.flat();
        // .filter((x) => x.symbol?.toLowerCase().includes(this.currentCoin.toLowerCase()));
      },
      get parent(): IBotStore {
        let parent: any = getParent(self, 2);
        return parent;
      },
      get supportedMarkets(): string[] {
        return this.parent.supportedMarkets;
      },
      get accountInfo(): any {
        return {
          id: `ID 123343`,
          email: 'joe.biden@chakra-ui.com',
          accounts: this.parent.accountNames,
          logo: 'https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzV8fG1hbiUyMHNpbWxpbmd8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=100',
          activeAccount: self.owner,
        };
      },
      get currentCoin() {
        return self.selectedCoin || this.coins[0];
      },
      get filteredJobs() {
        if (self.joblistFilter.toLowerCase() === 'long jobs') {
          return this.defaultConfig.longJobs;
        }
        if (self.joblistFilter.toLowerCase() === 'short jobs') {
          return this.defaultConfig.shortJobs;
        }
        if (self.joblistFilter.toLowerCase() === 'other jobs') {
          return this.defaultConfig.otherJobs;
        }
        return this.defaultConfig.jobs;
      },
      get supportedCoins() {
        return allCoins(this.supportedMarkets);
      },
      get coins() {
        let result = [...new Set(self.configs.map((o) => o.baseAsset))];
        return result.filter((x) => Boolean(x));
      },
      get marketsToAdd() {
        let markets = self.configs.map((o) => o.symbol);
        return this.supportedMarkets
          .filter((o) => !markets.includes(o))
          .filter((x) => {
            let coin = asCoins(x);
            return coin === self.selectedCoin;
          });
      },
      get markets() {
        return self.selectedCoin
          ? self.configs.filter((o) => o.baseAsset === self.selectedCoin)
          : [];
      },
      get usdMarkets() {
        return self.configs
          .filter((o) => o._type === 'usdt')
          .filter((o) => !o.marketPaused);
      },
      get coinMarkets() {
        return self.configs
          .filter((o) => o._type === 'coin')
          .filter((o) => !o.marketPaused);
      },
      get defaultConfig() {
        return self.config || this.markets[0] || self.configs[0];
      },
      get display() {
        return `${self.owner} ${defaultConfig().symbol}`;
      },
      spotInstance(symbol: string) {
        return this.spot_markets.find(
          (o) => o.symbol.toLowerCase() === symbol.toLowerCase(),
        );
      },
      get generalConfig() {
        let c = defaultConfig();
        let result = [
          { text: 'Budget', value: c.budget },
          { text: 'Minimum Balance', value: c.minimum_balance },
          { text: 'Maximum Size', value: c.maximum },
          { text: 'Support', value: c.ema ? `Ema ${c.ema}` : c.support },
          {
            text: 'Ideal Stop',
            value: c.ideal_ema ? `Ema ${c.ideal_ema}` : c.ideal_stop,
          },
          { text: 'Bias', value: c.bias },
        ];
        if (c.acceptable_loss) {
          result.push({
            text: 'Acceptable Loss',
            value: c.acceptable_loss.toString(),
          });
          result.push({ text: 'Sell Spread', value: c.sell_spread });
        }
        return result;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const bulkDeleteJobs: any = flow(function* bulkDeleteJobs(
      jobs: IJobStore[] | undefined,
    ) {
      try {
        if (jobs) {
          let arr = jobs.map((o) =>
            adapter.deleteJob({
              job_name: o.name,
              owner: self.owner,
              symbol: self.defaultConfig.symbol,
            }),
          );
          self.loading = true;
          let response = yield Promise.all(arr);
          yield self.parent.getBotAccounts();
          self.loading = false;
        }
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    function setDefaultCoin() {
      if (self.defaultConfig) {
        self.selectedCoin = self.defaultConfig.baseAsset;
        self.selectedMarket = self.defaultConfig.symbol;
      }
    }
    function updateConfigList(configs: any[]) {
      self.config = null;
      if (configs.length > 0) {
        self.configs = cast(configs);
      }
    }
    const onProtectProfit = flow(function* onProtectProfit(symbol) {
      try {
        self.loading = true;
        yield adapter.updateProtectProfit({ symbol, owner: self.owner });
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const fetchBotOrders = flow(function* fetchBotOrders() {
      try {
        self.loading = true;
        let result = yield adapter.updateOrders(
          self.owner,
          self.defaultConfig.symbol,
          false,
        );
        self.loading = false;
        //console.log(result.api_orders);
        return result;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const updateOrders = flow(function* updateOrders() {
      try {
        self.loading = true;
        let result = yield adapter.updateOrders(
          self.owner,
          self.defaultConfig.symbol,
        );
        self.loading = false;
        //console.log(result);
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const tempPauseMarket = flow(function* tempPauseMarket(symbol: string) {
      try {
        self.loading = true;
        yield adapter.tempPauseMarket({ symbol, owner: self.owner });
        self.loading = false;
        self.parent.getBotAccounts();
      } catch (error) {
        throw error;
      }
    });
    const fetchAllSpotMarkets = flow(function* fetchAllSpotMarkets(
      force = false,
    ) {
      try {
        return yield adapter.getSpotSymbols({
          coin: self.selectedCoin,
          owner: self.owner,
        });
      } catch (error) {
        throw error;
      }
    });
    const fetchAllMarkets = flow(function* fetchAllMarkets(force = false) {
      try {
        const { allMarkets } = yield adapter.fetchAllMarkets(self.owner);
        return allMarkets;
      } catch (error) {
        throw error;
      }
    });
    const getAccountInfo = flow(function* getAccountInfo() {
      try {
        let { symbol, bias } = self.defaultConfig;
        const {
          positions,
          balance,
          stop_orders,
          // allMarkets,
        } = yield adapter.loadInitialData(symbol, bias, `/ft/${self.owner}`);
        return { positions, bias, balance, stop_orders };
      } catch (error) {
        console.error('Error occured in getAccountInfo', error);
      }
    });
    const updateBackgroundWatch = flow(function* updateBackgroundWatch() {
      try {
        let config = self.defaultConfig;
        self.loading = true;
        if (config.job_id) {
          yield adapter.removeSymbolFromBackground(self.owner, config.symbol);
        } else {
          yield adapter.addSymbolToBackground(self.owner, config.symbol);
        }
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });

    const runAction = flow(function* runAction(data: {
      type: string;
      name: string;
    }) {
      let config = self.defaultConfig;
      try {
        self.loading = true;
        if (data.type === 'job') {
          yield adapter.runJob({
            data,
            owner: self.owner,
            symbol: config.symbol,
          });
        } else if (data.type === 'alert') {
          //console.log('job executed');
        }
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const pauseAction = flow(function* pauseAction(data: {
      type: string;
      name: string;
    }) {
      let config = self.defaultConfig;
      try {
        self.loading = true;
        if (data.type === 'job') {
          yield adapter.pauseJob({
            data,
            owner: self.owner,
            symbol: config.symbol,
          });
        } else if (data.type === 'alert') {
          //console.log('job executed');
        }
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const deleteJobInstance = flow(function* deleteJobInstance(
      job_name: string,
    ) {
      let config = self.defaultConfig;
      try {
        self.loading = true;
        yield adapter.deleteJob({
          job_name,
          owner: self.owner,
          symbol: config.symbol,
        });
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const editSpotProfile: any = flow(function* editSpotProfile(
      spot_symbol: string,
    ) {
      let foundSpot = self.spotInstance(spot_symbol);
      if (foundSpot) {
        self.loading = true;
        yield foundSpot.editProfile(self.owner);
        self.loading = false;
      }
    });
    const addSpotSymbols: any = flow(function* addSpotSymbols(
      symbol: string | string[],
    ) {
      const allSymbols = Array.isArray(symbol) ? symbol : [symbol];
      if (
        true // self.configs.filter((o) => allSymbols.includes(o.symbol)).length == 0
      ) {
        try {
          self.loading = true;
          let symbol = self.defaultConfig.symbol;
          if (SpecialCoins.includes(self.currentCoin)) {
            symbol = 'BTCUSDT';
          }
          yield Promise.all(
            allSymbols.map((o) =>
              adapter.createSpotProfile({
                owner: self.owner,
                symbol,
                spot_symbol: o,
                profile: { symbol: o },
              }),
            ),
          );
          yield self.parent.getBotAccounts();
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
    });
    const addNewSymbol = flow(function* addNewSymbol(
      symbol: string | string[],
    ) {
      const allSymbols = Array.isArray(symbol) ? symbol : [symbol];
      if (
        self.configs.filter((o) => allSymbols.includes(o.symbol)).length == 0
      ) {
        try {
          self.loading = true;
          yield Promise.all(
            allSymbols.map((o) => adapter.addNewSymbol(self.owner || '', o)),
          );
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
    });

    const deleteSymbol = flow(function* deleteSymbol(symbol: string) {
      try {
        self.loading = true;
        // delete jobs
        yield bulkJobAction('Delete all jobs', symbol, true);
        // delete symbol
        let result = yield adapter.deleteSymbol(self.owner, symbol);
        updateConfigList(result);
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const onCreateTakeProfit = flow(function* onCreateTakeProfit(
      kind: 'long' | 'short',
      data,
    ) {
      let config = self.defaultConfig;
      //console.log({ ...data, kind });
      try {
        self.loading = true;
        yield adapter.createJob({
          owner: self.owner,
          symbol: config.symbol,
          type: 'new_order_sell',
          kind: kind,
          variable: 'size',
          operator: '<=',
          name: `custom takeprofit for ${kind} at ${data.takeProfit}`,
          value: data.quantity,
          entry_target: data.takeProfit,
          size: data.quantity,
          min_size: data.quantity,
          spread: 1,
        });
        self.loading = false;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    });

    const editJobInstance = flow(function* editJobInstance() {
      let config = self.defaultConfig;
      if (config.editJob) {
        let obj = {
          owner: self.owner,
          symbol: config.symbol,
          job: {
            ...getSnapshot(config.editJob),
            // name: config.editJob.displayName,
          },
        };
        try {
          self.loading = true;
          let result = yield adapter.editJob(obj);
          config.updateEditedJob(result);
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
    });
    const onDeleteProfile = flow(function* onDeleteProfile(
      profile_to_delete?: any,
    ) {
      try {
        self.loading = true;
        let func = adapter.deleteProfile;
        yield func({
          owner: self.owner,
          symbol: self.defaultConfig.symbol,
          profile: profile_to_delete.data,
        });
        self.loading = false;
      } catch (error) {
        throw error;
      }
    });
    const deleteSpotProfile: any = flow(function* deleteSpotProfile(
      spot_symbol: string,
    ) {
      //Todo to uptimize by just deleting it from the store instead of
      // reloading the getBotAccounts
      let instance = self.spotInstance(spot_symbol);
      if (instance) {
        try {
          self.loading = true;
          yield adapter.deleteSpotProfile({
            owner: self.owner,
            symbol: self.defaultConfig.symbol,
            spot_symbol,
          });

          let remaining: any = self.spot_markets
            .filter((o) => o.symbol != instance?.symbol)
            .map((j) => getSnapshot(j));
          let config = instance.parent as IBotConfig;
          config.updateSpotMarkets(remaining);
          self.loading = false;
          // yield self.parent.getBotAccounts();
        } catch (error) {
          throw error;
        }
      }
    });
    const onEditProfile = flow(function* onEditProfile(
      profile_to_delete?: any,
    ) {
      let editProfile = self.defaultConfig.currentProfile;
      let data = editProfile?.data;
      try {
        self.loading = true;
        let func = editProfile.id ? adapter.editProfile : adapter.createProfile;
        if (profile_to_delete) {
          data = profile_to_delete;
          func = adapter.deleteProfile;
        }
        yield func({
          owner: self.owner,
          symbol: self.defaultConfig.symbol,
          profile: data,
        });
        self.loading = false;
      } catch (error) {
        throw error;
      }
    });

    const removeOpenOrders = flow(function* removeOpenOrders(symbol) {
      try {
        self.loading = true;
        yield adapter.removeOpenOrders({ symbol, owner: self.owner });
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const updateSpotupdateTPSL = flow(function* updateTPSL(symbol: string) {
      let spotSymbol = self.spotInstance(symbol);
      if (spotSymbol) {
        yield spotSymbol.updateTPSL(self.owner);
        spotSymbol.toggleLoading(false);
      }
    });
    const runSpotProfile = flow(function* runSpotProfile(symbol: string) {
      let spotSymbol = self.spotInstance(symbol);
      if (spotSymbol) {
        yield spotSymbol.runProfile(self.owner);
        spotSymbol.toggleLoading(false);
      }
    });
    const runProfile: any = flow(function* runProfile(symbol: string) {
      let editProfile = self.defaultConfig.editProfile;
      if (editProfile) {
        yield editProfile.runProfile(symbol, self.owner); // jobs needs to be updated
      }
    });
    const bulkJobAction = flow(function* bulkJobAction(
      config: string,
      symbol: string,
      skip = false,
    ) {
      const func: any = {
        'Pause all jobs': 'pause',
        'Resume all jobs': 'resume',
        'Delete all jobs': 'delete',
      };
      try {
        if (!skip) {
          self.loading = true;
        }
        let action = func[config];
        if (!action) {
          // we need to run the profiles
          let editProfile = self.defaultConfig.editProfileId;
          yield adapter.runProfile({
            owner: self.owner,
            symbol,
            profile_id: editProfile,
          });
        } else {
          yield adapter.bulkJobAction({
            owner: self.owner,
            action: func[config],
            symbol,
          });
        }
        if (!skip) {
          self.loading = false;
        }
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const getJobStatus: any = flow(function* getJobStatus(job_id: string) {
      try {
        self.loading = true;
        let result = yield self.parent.getJobStatus(job_id);
        self.loading = false;
        return result;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const closePosition = flow(function* closePosition(
      symbol: string,
      kind: any,
    ) {
      try {
        self.loading = true;
        yield Promise.all([
          bulkJobAction('Delete all jobs', symbol),
          removeOpenOrders(symbol),
        ]);
        yield adapter.closePosition({
          owner: self.owner,
          symbol,
          kind,
        });
        self.loading = false;
      } catch (error) {
        throw error;
      }
    });
    const initializeFuture = function (symbol: string) {
      let foundSpot = self.spotInstance(symbol);
      //console.log(self.defaultConfig.leverage);
      if (foundSpot) {
        // foundSpot.initializeFuture(self.owner);
        applySnapshot(self.futureCTrader, {
          config: {
            budget: foundSpot.profile.futureBudget,
            percent_change: foundSpot.profile.percent_change,
            min_size: foundSpot.profile.min_size,
            support: foundSpot.profile.futureSupport,
            resistance: foundSpot.profile.futureResistance,
            sub_accounts: [...foundSpot.profile.sub_accounts],
            risk_reward: foundSpot.profile.risk_reward,
            zone_risk: foundSpot.profile.zone_split,
            gap: foundSpot.profile.gap,
            focus: foundSpot.profile.focus,
            price_places: foundSpot.profile.price_places,
            risk_per_trade: foundSpot.profile.risk_per_trade,
            tradeSplit: foundSpot.profile.tradeSplit,
            qtyPerTrade: foundSpot.profile.bull_factor,
            margin_sub_account: foundSpot.profile.margin_sub_account,
            max_size: foundSpot.profile.max_size,
          },
          max_size: foundSpot.profile.max_size,
          symbol,
          support: foundSpot.profile.futureSupport,
          resistance: foundSpot.profile.futureResistance,
          split: foundSpot.profile.tradeSplit,
          account: self.owner,
          price_places: foundSpot.profile.price_places,
          decimal_places: foundSpot.profile.decimal_places,
          zone_split: foundSpot.profile.zone_split,
          profit_base_price: foundSpot.profile.profit_base_price,
          profit_quote_price: foundSpot.profile.profit_quote_price,
          // positionStore: .acc
        });
      }
    };
    const initializedSolvedTrader = flow(function* initializedSolvedTrader(
      symbol: string,
    ) {
      initializeFuture(symbol);
      applySnapshot(self.solvedTrader, {
        symbol,
        owner: self.owner,
        futureCTrader: self.futureCTrader.toJSON(),
      });
      console.log('owner', self.solvedTrader.owner);
      try {
        yield self.solvedTrader.onFetchAccounts();
        initializeFuture(symbol);
        applySnapshot(self.solvedTrader, {
          ...getSnapshot(self.solvedTrader),
          futureCTrader: self.futureCTrader.toJSON(),
          owner: self.owner,
        });
        // create the trade zones
        self.solvedTrader.buildTradeZones();
      } catch (error) {
        console.log('error');
      }
    });
    return {
      initializedSolvedTrader,
      initializeFuture,
      deleteSpotProfile,
      runSpotProfile,
      updateSpotupdateTPSL,
      editSpotProfile,
      getJobStatus,
      onProtectProfit,
      addSpotSymbols,
      tempPauseMarket,
      closePosition,
      bulkJobAction,
      removeOpenOrders,
      onEditProfile,
      onDeleteProfile,
      editJobInstance,
      onCreateTakeProfit,
      deleteSymbol,
      addNewSymbol,
      deleteJobInstance,
      pauseAction,
      runAction,
      updateBackgroundWatch,
      getAccountInfo,
      fetchAllMarkets,
      fetchAllSpotMarkets,
      updateOrders,
      fetchBotOrders,
      runProfile,
      setJobListFilter(value: string) {
        self.joblistFilter = value;
      },
      setDefaultCoin,
      bulkDeleteJobs,
      setSelectedCoin(coin: string) {
        self.selectedCoin = coin;
        let markets = self.configs.filter((o) => o.baseAsset === coin);
        // self.config = self.markets[0];
        if (markets[0]) {
          this.setDefaultConfig(markets[0].symbol);
        }
        // self.parent.onSymbolChange();
      },
      setCurrentExchange(exchange: string) {
        self.currentExchange = exchange;
      },
      resetExchange() {
        self.currentExchange = undefined;
      },
      setDefaultConfig(symbol: string) {
        let result = self.configs.find(
          (o) => o.symbol.toLowerCase() === symbol.toLowerCase(),
        );
        self.config = result ? BotConfig.create(getSnapshot(result)) : null;
        self.selectedMarket = self.config?.symbol || '';
      },
      updateConfigList,
    };
  });

const PositionViewStore = types
  .model()
  .views((_this: any) => {
    const self: IBarePositionStore = _this;
    let parent: IBotStore = getParent(self, 1);
    function getEntryPoint(config: IBotConfig, kind: 'long' | 'short') {
      let _longPosition = self.longPosition;
      let _shortPosition = self.shortPosition;
      let long_p = _longPosition
        ? {
            entry: _longPosition.entryPrice,
            size: _longPosition.quantity,
          }
        : { entry: 0, size: 0 };
      let short_p = _shortPosition
        ? {
            entry: _shortPosition.entryPrice,
            size: _shortPosition.quantity,
          }
        : { entry: 0, size: 0 };
      let liquidation = kind == 'long' ? config.support : config.resistance;
      let result = get_ideal_entry_and_size(
        liquidation,
        self.balance,
        long_p,
        short_p,
        kind,
        config.pricePlaces,
        config.decimalPlaces,
      );
      let { entry, size, additional } = result;
      let _additional: any;
      if (additional) {
        _additional = additional[kind];
      } else {
        _additional = {};
      }
      return { entry, size, _additional };
    }
    return {
      liquidationOrders(config: IBotConfig) {
        let { _additional: longAdditional } = getEntryPoint(config, 'long');
        let { _additional: shortAdditional } = getEntryPoint(config, 'short');
        let result = [];
        if (longAdditional.raw) {
          result.push({
            entry: longAdditional.raw.entry,
            quantity: longAdditional.raw.size,
            kind: 'long',
          });
          if (longAdditional.ideal) {
            result.push({
              entry: longAdditional.ideal.entry,
              quantity: longAdditional.ideal.size,
              kind: 'long',
            });
          }
        }
        if (shortAdditional.raw) {
          result.push({
            entry: shortAdditional.raw.entry,
            quantity: shortAdditional.raw.size,
            kind: 'short',
          });
          if (shortAdditional.ideal) {
            result.push({
              entry: shortAdditional.ideal.entry,
              quantity: shortAdditional.ideal.size,
              kind: 'short',
            });
          }
        }
        return result;
      },
      get profitHelper(): any {
        const config = parent.account?.defaultConfig;
        return profitHelper(self.longPosition, self.shortPosition, config);
      },
      get totalBalance() {
        let additional_budget: number =
          parent.account?.defaultConfig?.additional_budget || 0;
        return self.balance + additional_budget;
      },
      liquidationAnalysis(kind: 'pnl' | 'additional'): any {
        let config = parent.account?.defaultConfig || null;
        return liquidationAnalysis(
          kind,
          self.longPosition,
          self.shortPosition,
          config,
          this.totalBalance,
          // this.longPosition.
        );
      },
      entryPoint(config: IBotConfig, kind: 'long' | 'short') {
        let result = getEntryPoint(config, kind);
        let { entry, size, _additional } = result;
        let raw: any = _additional.raw;
        let ideal: any = _additional.ideal;
        return {
          heading: `size: ${size}${raw ? `/${raw.size}` : ''}`,
          body: `${kind === 'long' ? 'L' : 'S'} ${entry}`,
          footer: `${raw && `Entries: ${raw.entry.toFixed(2)}`}${
            ideal ? `,${ideal.entry.toFixed(2)}` : ``
          }`,
        };
      },
      get lossInfo() {
        let config = parent.account?.config;
        if (self.longPosition && self.shortPosition && config) {
          let c_price =
            self.currentPrice ||
            Math.min(
              self.longPosition.entryPrice,
              self.shortPosition.entryPrice,
            );
          let result = calculate_maximum_size(
            self.balance,
            {
              entry: self.longPosition.entryPrice,
              size: self.longPosition.quantity,
            },
            {
              entry: self.shortPosition.entryPrice,
              size: self.shortPosition.quantity,
            },
            c_price,
            self.longPosition.leverage,
            config.price_places,
            config.decimal_places,
          );
          return result;
        }
      },
      get analyze(): any {
        if (self.openTrade && parent.account) {
          let result = parent.account.defaultConfig.getSize(self.openTrade);
          let pnl = self.openTrade.determinePnl(
            self.openTrade.takeProfit || 0,
            self.openTrade.entryPrice,
            parseFloat(result.size),
          );
          return { ...result, pnl };
        }
        return { size: 0, takeProfit: 0, pnl: 0 };
      },
    };
  })
  .actions((_this: any) => {
    const self: IBarePositionStore = _this;
    let parent: IBotStore = getParent(self, 1);
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);

    function updateTradeConfig() {
      if (self.openTrade && parent.account) {
        let stopConfig = parent.account.defaultConfig.getStop(self.openTrade);
        self.openTrade.setStopLoss(stopConfig.stop);
        self.openTrade.setTakeProfit(stopConfig.takeProfit);
      }
    }
    function onAccountsFetched(
      positions: any[],
      bias: any,
      balance: number,
      stop_orders: any,
    ) {
      self.balance = balance;
      self.orders = cast(positions);
      if (positions.length > 0) {
        let result = positions.find((o: any) => o.kind == bias);
        if (result) {
          self.openTrade = Order.create(result);
          updateTradeConfig();
        }
      }
      if (stop_orders.long) {
        self.longStop = cast(stop_orders.long);
      }
      if (stop_orders.short) {
        self.shortStop = cast(stop_orders.short);
      }
    }
    const placeManualOrder: any = flow(function* updateStopTrade(
      // const updateStopTrade: any = flow(function* updateStopTrade(
      kind: 'long' | 'short',
    ) {
      let config = parent.account?.defaultConfig;
      let owner = parent.account?.owner;
      let order = {
        side: self.manualAction,
        price:
          kind == 'long'
            ? self.manualAction == 'buy'
              ? config?.long_e
              : _this.profitHelper.long.takeProfit
            : self.manualAction == 'sell'
            ? config?.short_e
            : _this.profitHelper.short.takeProfit,
        quantity:
          kind == 'long'
            ? self.manualAction == 'buy'
              ? config?.long_s
              : _this.profitHelper.long.quantity
            : self.manualAction == 'sell'
            ? config?.short_s
            : _this.profitHelper.short.quantity,
        kind,
      };
      //console.log({ order });
      try {
        self.loading = true;
        yield adapter.bulkTrades(owner || '', config?.symbol || '', [
          {
            price: order.price,
            quantity: order.quantity,
            kind: order.kind,
            side: order.side,
          },
        ]);
        self.loading = false;
        yield parent.getAccountInfo();
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const updateStopTrade: any = flow(function* placeManualOrder(
      kind: 'long' | 'short',
    ) {
      let config = parent.account?.defaultConfig;
      let owner = parent.account?.owner;
      let order = {
        entry: kind == 'long' ? config?.long_e : config?.short_e,
        size: kind == 'long' ? config?.long_s : config?.short_s,
        kind,
      };
      //console.log({ order });
      try {
        self.loading = true;
        yield adapter.updateStopTrade(owner || '', config?.symbol || '', order);
        self.loading = false;
        yield parent.getAccountInfo();
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const placeOrder: any = flow(function* placeOrder(
      config: IBotConfig,
      order: {
        entry: number;
        quantity: number;
        kind: string;
        side?: string;
        force_market?: boolean;
      },
      action: 'buy' | 'sell',
    ) {
      let owner = parent.account?.owner || '';
      try {
        self.loading = true;
        let params: any = {
          price: order.entry,
          quantity: order.quantity,
          kind: order.kind,
          side:
            order.side || action == 'buy'
              ? order.kind == 'long'
                ? 'buy'
                : 'sell'
              : order.kind == 'long'
              ? 'sell'
              : 'buy',
        };
        if (order.force_market) {
          params.force_market = order.force_market;
        }
        yield adapter.bulkTrades(owner, config.symbol, [params]);
        self.loading = false;
        yield parent.getAccountInfo();
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    return {
      updateTradeConfig,
      onAccountsFetched,
      placeOrder,
      placeManualOrder,
      updateStopTrade,
      setManualAction(value: string) {
        self.manualAction = value;
      },
      setOrderType(value: string) {
        self.orderType = value;
      },
    };
  });
const PositionStore = types
  .compose(BarePositionStore, PositionViewStore)
  .named('MergedPositionStore');

export const CalculatorStore = types
  .model('CalculatorStore', {
    symbol: types.optional(types.string, ''),
    positionStore: types.optional(PositionStore, {}),
    configs: types.optional(types.array(BotConfig), []),
  })
  .views((self) => {
    return {
      get defaultConfig() {
        let parent: any = getParent(self, 1);
        let config = self.configs.find((o) => o.symbol == self.symbol);
        if (parent) {
          config = parent.account?.defaultConfig;
          // config = parent.account.configs.find(
          //   (o: any) => o.symbol == self.symbol,
          // );
        }
        return config;
      },
      get leverage() {
        return this.defaultConfig?.leverage || 125;
      },
      get longPosition(): ISimplePosition {
        let config = this.defaultConfig;
        let params = {
          entryPrice: 0,
          quantity: 0,
          kind: 'long',
          contractSize: 0,
        };
        if (config) {
          params = {
            ...params,
            entryPrice: config.long_e,
            quantity: config.long_s,
            contractSize: config.contract_size
              ? config.long_s
              : Math.round(
                  (config.long_e * config.long_s) / config.contract_size,
                ),
          };
        }
        return params;
      },
      get shortPosition(): ISimplePosition {
        let config = this.defaultConfig;
        let params = {
          entryPrice: 0,
          quantity: 0,
          kind: 'short',
          contractSize: 0,
        };
        if (config) {
          params = {
            ...params,
            entryPrice: config.short_e,
            quantity: config.short_s,
            contractSize: config.contract_size
              ? config.short_s
              : Math.round(
                  (config.long_e * config.short_s) / config.contract_size,
                ),
          };
        }
        return params;
      },
      get hideFields() {
        return self.symbol.toLowerCase().includes('usd_')
          ? []
          : ['contract_size'];
      },
      get totalBalance() {
        let additional_budget: number =
          this.defaultConfig?.additional_budget || 0;
        return additional_budget;
      },
      optimumPositionSize(kind: 'long' | 'short') {
        let config = this.defaultConfig;
        if (config) {
          let profit_percent = kind == 'long' ? config.bias_r : config.other_r;
          let entry = kind == 'long' ? config.long_e : config.short_e;
          let stop = profit_percent * entry;
          let leverage = config.u_leverage;
          let capital = this.totalBalance;
          if (config.contract_size > 0) {
            capital =
              (this.totalBalance * entry * leverage) / config.contract_size;
          }
          let size = determine_position_size(entry, stop, capital);
          let leveraged_size = size * leverage;
          let direction = kind == 'long' ? 1 : -1;
          let new_stop = entry - direction * (capital / leveraged_size);
          let in_contract = config.contract_size
            ? (leveraged_size * entry) / config.contract_size
            : 0;
          return {
            size: leveraged_size,
            stop: new_stop,
            contract_size: Math.round(in_contract),
          };
        }
      },
      get profitHelper(): any {
        let result = profitHelper(
          this.longPosition,
          this.shortPosition,
          this.defaultConfig,
          this.defaultConfig?.contract_size,
          this.totalBalance,
        );
        return result;
      },
      liquidationAnalysis(kind: 'pnl' | 'additional') {
        return liquidationAnalysis(
          kind,
          this.longPosition,
          this.shortPosition,
          this.defaultConfig,
          this.totalBalance,
          this.defaultConfig?.leverage || 125,
          (config: IBotConfig) => ({
            long: { entry: config.along_e, size: config.along_s },
            short: { entry: config.ashort_e, size: config.ashort_s },
          }),
          this.defaultConfig?.contract_size,
        );
      },
    };
  })
  .actions((self) => {
    return {
      initialize(
        _configs: any,
        symbol: string,
        positionStore: any,
        leverage: number,
      ) {
        self.symbol = symbol;
        self.positionStore = cast(positionStore);
        self.configs = cast(_configs);
        if (symbol.toLowerCase().includes('_perp')) {
          let cc = symbol.toLowerCase().includes('btc') ? 100 : 10;
          self.defaultConfig?.updateFields({
            leverage: leverage,
            contract_size: cc,
          });
        } else {
          self.defaultConfig?.updateFields({ contract_size: 0 });
        }
        if (self.defaultConfig) {
          self.defaultConfig.updateFields({
            additional_budget: self.positionStore.balance,
            budget: 0,
          });
        }
        if (self.positionStore.longPosition?.entryPrice) {
          let { entryPrice, quantity } = self.positionStore.longPosition;
          self.defaultConfig?.updateFields({
            long_e: entryPrice,
            long_s: quantity,
          });
        }
        if (self.positionStore.shortPosition?.entryPrice) {
          let { entryPrice, quantity } = self.positionStore.shortPosition;
          self.defaultConfig?.updateFields({
            short_e: entryPrice,
            short_s: quantity,
          });
        }
      },
    };
  });

export const BotStore = types
  .model('BotStore', {
    account: types.maybeNull(types.reference(Account)),
    accounts: types.optional(types.array(Account), []),
    config: types.optional(types.string, ''),
    loading: types.optional(types.boolean, false),
    account_loading: types.optional(types.boolean, false),
    positionStore: types.optional(PositionStore, {}),
    jobCreate: types.optional(JobInstance, {}),
    alertCreate: types.optional(AlertStore, {}),
    calculator: types.optional(CalculatorStore, {}),
    calculatorMode: types.optional(types.boolean, true),
    supportedSpotMarkets: types.optional(types.array(types.string), []),
    supportedMarkets: types.optional(types.array(types.string), []),
    mobile: types.optional(MobileRootStore, {
      accounts: [],
    }),
  })
  .views((self) => {
    function getParamForField(field: string, isGroup?: string): any | any[] {
      if (isGroup === 'group' && field === 'checkbox') {
        return configs
          .filter((o) => o.kind === field && o.group === true)
          .map((o) => {
            let value: any = '';
            if (self.account) {
              let oo: any = self.account.defaultConfig;
              value = oo[o.name];
            }
            // let value = self.account?.defaultConfig[o.name]
            return { ...o, value };
          });
      }
      let r: any = configs.find((o) => o.name == field);
      if (r) {
        let tt: any = '';
        if (self.account) {
          let oo: any = self.account.defaultConfig;
          tt = oo[r.name];
        }
        r.value = tt;
      }
      return r;
    }
    return {
      get editJob() {
        return self.account?.defaultConfig?.editJob;
      },
      get editAlert() {
        return self.account?.defaultConfig?.editAlert;
      },
      get accountNames() {
        return self.accounts.map((o) => o.owner);
      },
      getParamForField,
      get config_fields() {
        return config_fields;
      },
      get configFieldsOptions() {
        return ['Config 1', 'Config 2', 'Config 3', 'Config 4'];
      },
      get formatConfigValue() {
        let _options: any = {
          config_1: 'Config 1',
          config_2: 'Config 2',
          config_3: 'Config 3',
          config_4: 'Config 4',
        };
        return _options[self.config] || '';
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const userConfigs = `allAccount_configs`;
    const defaultAccount = `defaultAccount`;
    const marketStorageName = `supportedMarkets`;
    const spotMarketStorageName = `supportedSpotMarkets`;
    function getSavedConfig() {
      const defaultConfig = `${self.account?.owner}_defaultConfig'`;
      let lM = window.localStorage.getItem(defaultConfig);
      if (lM && lM !== 'undefined' && lM !== 'null') {
        self.config = lM;
      }
    }
    function initializeStorage() {
      let cM = window.localStorage.getItem(userConfigs);
      if (cM && cM !== 'undefined' && cM != 'null') {
        let r = JSON.parse(cM);
        self.accounts = cast(r);
      }
      let sD = window.localStorage.getItem(marketStorageName);
      if (sD && sD !== 'undefined' && sD != 'null') {
        let o = JSON.parse(sD);
        self.supportedMarkets = cast(o);
      }
      let ssD = window.localStorage.getItem(spotMarketStorageName);
      if (ssD && ssD !== 'undefined' && ssD != 'null') {
        let u = JSON.parse(ssD);
        self.supportedSpotMarkets = cast(u);
      }
      let dA = window.localStorage.getItem(defaultAccount);
      if (dA && dA !== 'undefined' && dA != 'null') {
        if (self.accounts) {
          let act = self.accounts.find((o) => o.owner === dA);
          if (act) {
            self.account = act;
          }
        }
      }
      getSavedConfig();
    }
    function getCleanedConfig() {
      let v = {};
      if (self.account) {
        v = self.account.defaultConfig;
      }
      let _config: any = getSnapshot(v);
      let value: any = {};
      Object.keys(_config).map((o) => {
        if (_config[o] != null) {
          value[o] = _config[o];
        }
      });
      return value;
    }
    const fetchBotOrders = flow(function* fetchBotOrders() {
      if (self.account) {
        let result = yield self.account.fetchBotOrders();
        self.positionStore.updateServerOrders(result.api_orders);
      }
    });
    const updateOrders = flow(function* updateOrders() {
      if (self.account) {
        yield self.account.updateOrders();
      }
    });
    const updateConfig = flow(function* updateConfig() {
      let owner = self.account?.owner || '';
      window.localStorage.setItem(defaultAccount, owner);
      // update accounts with account data
      let accountSnapshot: any = getSnapshot(self.accounts);
      accountSnapshot = accountSnapshot.map((o: any) => {
        if (o.owner === owner && self.account) {
          let oS = getSnapshot(self.account);
          return oS;
        }
        return o;
      });
      window.localStorage.setItem(userConfigs, JSON.stringify(accountSnapshot));
      try {
        self.loading = true;
        let owner = self.account?.owner || '';
        // yield adapter.updateConfig(owner, getCleanedConfig());
        // yield fetchAccounts()
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    function setSupportedMarkets(value: string[]) {
      self.supportedMarkets = cast(value);
      window.localStorage.setItem(marketStorageName, JSON.stringify(value));
    }
    function setSupportedSpotMarkets(value: string[]) {
      self.supportedSpotMarkets = cast(value);
      window.localStorage.setItem(spotMarketStorageName, JSON.stringify(value));
    }
    const fetchAllMarkets = flow(function* fetchAllMarkets(force = false) {
      if (
        self.supportedMarkets.length == 0 ||
        self.supportedSpotMarkets.length == 0 ||
        force
      ) {
        if (self.account) {
          const [allMarkets, allSpotMarkets] = yield Promise.all([
            self.account.fetchAllMarkets(force),
            self.account.fetchAllSpotMarkets(force),
          ]);
          setSupportedMarkets(allMarkets);
          setSupportedSpotMarkets(allSpotMarkets);
        }
      }
    });
    const getAccountInfo = flow(function* getAccountInfo() {
      try {
        if (self.account) {
          let {
            bias = '',
            positions,
            balance,
            stop_orders,
          } = yield self.account.getAccountInfo();
          // setSupportedMarkets(allMarkets);
          self.positionStore.onAccountsFetched(
            positions,
            bias,
            balance,
            stop_orders,
          );
        }
      } catch (error) {
        //console.log(error);
      } finally {
      }
    });
    const getBotAccounts = flow(function* getBotAccounts(skip = false) {
      try {
        self.account_loading = true;
        const result = yield adapter.getBotAccounts();
        //console.log({ result });
        // save any default state on previous accounts
        let oldAccounts = [];
        let oldConfig = self.account ? self.account.defaultConfig.symbol : '';
        oldAccounts = result;
        // }
        self.accounts = cast(oldAccounts);
        if (self.account) {
          let act = self.accounts.find((o) => o.owner === self.account?.owner);
          if (act) {
            self.account = act;
            if (!skip) {
              self.account.setDefaultConfig(oldConfig);
              //console.log({ oldConfig });
            }
          }
        } else {
          if (!skip) {
            self.account = self.accounts[0];
          }
        }
        self.account?.setDefaultCoin();
        self.account_loading = false;
        updateConfig();
      } catch (error) {
        self.account_loading = false;
        // //console.log(error);
        throw error;
      }
    });
    const fetchAccounts = flow(function* fetchBotAccounts() {
      try {
        self.loading = true;
        yield getBotAccounts();
        yield getAccountInfo();
        if (self.calculatorMode) {
          updateCalculatorState();
        }
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const updateBackgroundWatch = flow(function* updateBackgroundWatch() {
      if (self.account) {
        yield self.account.updateBackgroundWatch();
        yield getBotAccounts();
      }
    });
    const runAction = flow(function* runAction(data: {
      type: string;
      name: string;
    }) {
      if (self.account) {
        yield self.account.runAction(data);
        // if([].includes())
        // yield getBotAccounts();
      }
    });
    const pauseAction = flow(function* pauseAction(data: {
      type: string;
      name: string;
    }) {
      if (self.account) {
        yield self.account.pauseAction(data);
        yield getBotAccounts();
      }
    });
    const deleteJobInstance = flow(function* deleteJobInstance(
      job_name: string,
    ) {
      if (self.account) {
        yield self.account.deleteJobInstance(job_name);
        yield getBotAccounts();
      }
    });
    const addNewSymbol = flow(function* addNewSymbol(
      symbol: string | string[],
    ) {
      if (self.account) {
        yield self.account.addNewSymbol(symbol);
        yield getBotAccounts(true);
      }
    });
    const closePosition = flow(function* closePosition(
      symbol: string,
      kind: any,
    ) {
      if (self.account) {
        yield self.account.closePosition(symbol, kind);
      }
      yield getBotAccounts();
    });
    const deleteSymbol = flow(function* deleteSymbol(symbol: string) {
      if (self.account) {
        yield self.account.deleteSymbol(symbol);
        yield getBotAccounts();
      }
    });
    const onCreateTakeProfit = flow(function* onCreateTakeProfit(
      kind: 'long' | 'short',
      data,
    ) {
      if (self.account) {
        yield self.account.onCreateTakeProfit(kind, data);
        yield getBotAccounts();
      }
    });
    const editAlertInstance = flow(function* editAlertInstance() {});
    const editJobInstance = flow(function* editJobInstance() {
      if (self.account) {
        return yield self.account.editJobInstance();
      }
    });
    const onDeleteProfile = flow(function* onDeleteProfile(
      profile_to_delete?: any,
    ) {
      if (self.account) {
        yield self.account.onDeleteProfile(profile_to_delete);
        yield getBotAccounts();
      }
    });
    const onEditProfile = flow(function* onEditProfile(
      profile_to_delete?: any,
    ) {
      if (self.account) {
        yield self.account.onEditProfile(profile_to_delete);
        yield getBotAccounts();
      }
    });
    const removeOpenOrders = flow(function* removeOpenOrders(symbol) {
      if (self.account) {
        return yield self.account.removeOpenOrders(symbol);
      }
    });
    const bulkJobAction = flow(function* bulkJobAction(
      config: string,
      symbol: string,
      skip = false,
    ) {
      if (self.account) {
        yield self.account.bulkJobAction(config, symbol, skip);
        yield getBotAccounts();
      }
    });
    const toggleJobsInstance = flow(function* toggleJobsInstance(values: {
      action: string;
      job_status: string[];
      exclude?: string[];
    }) {
      if (self.account) {
        try {
          self.loading = true;
          yield adapter.toggleJobsWithAction({
            owner: self.account.owner,
            ...values,
          });
          yield getBotAccounts();
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
    });
    const getJobStatus = flow(function* getJobStatus(job_id: string) {
      try {
        self.loading = true;
        let result = yield adapter.getJobStatus(job_id);
        self.loading = false;
        return result;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const createAlertInstance = flow(function* createAlertInstance() {});
    const createJobInstance = flow(function* createJobInstance() {
      if (self.account) {
        let config = self.account.defaultConfig;
        let name = null;
        let pp = Math.random();
        let exists = config.job_ids.filter((o) =>
          o.includes(self.jobCreate.value),
        );
        if (exists.length > 0) {
          let _kind = self.jobCreate.config.kind || '';
          name = `${_kind}_${exists[0]}_${exists.length + 1}_${pp}`;
        }
        let obj = {
          ...self.jobCreate.dataToSubmit,
          owner: self.account.owner,
          symbol: config.symbol,
        };
        if (self.account.currentExchange) {
          obj.exchange = self.account.currentExchange;
        }
        if (name) {
          obj.name = name;
        }
        try {
          self.loading = true;
          yield adapter.createJob(obj);
          self.account.resetExchange();
          yield getBotAccounts();
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
    });
    const updateCalculatorState = () => {
      let str: any = self.account?.configs || [];
      self.calculator.initialize(
        getSnapshot(str),
        self.account?.defaultConfig?.symbol || '',
        getSnapshot(self.positionStore),
        self.positionStore.leverage,
      );
    };
    const onSymbolChange = flow(function* onSymbolChange(config?: IBotConfig) {
      config && self.account?.setDefaultConfig(config.symbol);
      self.loading = true;
      yield getAccountInfo();
      if (self.calculatorMode) {
        updateCalculatorState();
      }
      self.loading = false;
    });
    return {
      setEditJob(job: any, index: number) {
        if (self.account) {
          self.account?.defaultConfig.setEditJob(job, index);
        }
      },
      editAlertInstance,
      editJobInstance,
      pauseAction,
      runAction,
      onCreateTakeProfit,
      deleteSymbol,
      onSymbolChange,
      createAlertInstance,
      createJobInstance,
      deleteJobInstance,
      toggleJobsInstance,
      bulkJobAction,
      removeOpenOrders,
      onEditProfile,
      updateBackgroundWatch,
      getBotAccounts,
      fetchBotOrders,
      closePosition,
      onDeleteProfile,
      updateLoading(loading: boolean) {
        self.loading = loading;
      },
      afterCreate() {
        initializeStorage();
        fetchAllMarkets();
        // getAccountInfo();
        fetchAccounts();
      },
      switchAccount(account: string) {
        const act = self.accounts.find((o) => o.owner === account);
        if (act) {
          self.account = act;
        }
        window.localStorage.setItem(defaultAccount, account);
        getSavedConfig();
        return onSymbolChange();
        // return getAccountInfo();
      },
      fetchAccounts,
      updateOrders,
      getJobStatus,
      setCalculatorMode(val: boolean) {
        self.calculatorMode = val;
      },
      switchToCalculator() {
        updateCalculatorState();
        this.setCalculatorMode(true);
      },
      getAccountInfo,
      addNewSymbol,
      onConfigSelect(config: string) {
        let result = '';
        if (config === 'Config 1') {
          result = 'config_1';
          self.account?.defaultConfig.updateFields({
            follow_trend: false,
            strategy: null,
          });
        }
        if (config == 'Config 2') {
          result = 'config_2';
          self.account?.defaultConfig.updateFields({
            strategy: 'profit_maximizer',
            follow_trend: false,
          });
        }
        if (config == 'Config 3') {
          result = 'config_3';
          self.account?.defaultConfig.updateFields({
            strategy: 'profit_maximizer',
            follow_trend: true,
            bias_only: true,
          });
        }
        if (config == 'Config 4') {
          result = 'config_4';
          self.account?.defaultConfig.updateFields({
            strategy: 'loss_minimizer',
            follow_trend: false,
          });
        }
        self.config = result;
        const defaultConfig = `${self.account?.owner}_defaultConfig'`;
        window.localStorage.setItem(defaultConfig, result);
      },
      updateConfig,
    };
  });

interface ISimplePosition {
  entryPrice: number;
  quantity: number;
  kind: 'long' | 'short' | string;
  contractSize: number;
}

export interface IBotStore extends Instance<typeof BotStore> {}
export interface IAccount extends Instance<typeof Account> {}
export interface IPositionStore extends Instance<typeof PositionStore> {}
export interface ICalculatorStore extends Instance<typeof CalculatorStore> {}
export interface IBotConfig extends Instance<typeof BotConfig> {}
