import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getParent,
  getSnapshot,
  types,
} from 'mobx-state-tree';
import { BotAdapterType } from '../../adapter';
import { FutureInstance, IFutureInstance } from '../futureConfig';
import { createArray, getParamForField, groupBy, to_f } from '../utils';
import MarketTypeInstances, {
  IMarketTypeInstance,
  newAccountDefault,
} from './account';
import {
  FutureOrder,
  MainTradeConfig,
  MarginOrder,
} from './margin_type_config';
import { AIStore, TradeZoneStore } from './trade-zone';
import { Signal, SignalConfigType } from '../trade_signal';
import { ExchangeStore } from './exchangeStore';

export const SECTIONS = {
  ACCOUNT_LIST: 'account-list',
  MARGIN_ORDERS: 'margin-orders',
  FUTURE_ORDERS: 'future-orders',
};

const config_fields: any = {
  config_1: ['support', 'resistance', 'kind'],
};

const selectProps = {
  valueLayout: (v: any) => v.label,
  optionLayout: (v: any) => v.value,
};
const configs = [
  {
    name: 'kind',
    label: 'Trade Kind',
    kind: 'select',
    options: ['long', 'short'],
  },
  {
    name: 'owner',
    label: 'Owner',
    kind: 'select',
    options: [],
  },
  {
    name: 'increase',
    label: 'Increase position?',
    kind: 'checkbox',
  },
  {
    name: 'use_default',
    label: 'Use default?',
    kind: 'checkbox',
  },
  {
    name: 'support',
    label: 'Support',
    kind: 'special',
    ...selectProps,
  },
  {
    name: 'resistance',
    label: 'Resistance',
    kind: 'special',
    ...selectProps,
  },
  {
    name: 'entry',
    label: 'Entry',
    kind: 'special',
    controlProps: {
      maxW: 'full',
    },
    ...selectProps,
  },
  {
    name: 'stop',
    label: 'Stop',
    kind: 'special',
    ...selectProps,
  },
  {
    name: 'size',
    label: 'Size',
    kind: 'special',
    props: {
      variant: 'unstyled',
      isReadOnly: true,
    },
  },
  {
    name: 'risk',
    label: 'Risk',
    kind: 'special',
  },
];

export const NewEntryZoneConfig = types
  .model('NewEntryZoneConfig', {
    entry: types.optional(types.number, 0),
    stop: types.optional(types.number, 0),
    size: types.optional(types.number, 0),
    risk: types.optional(types.number, 0),
    increase: types.optional(types.boolean, true),
    use_default: types.optional(types.boolean, false),
    // this doesn't display directly on the form.
    zone_index: types.optional(types.number, -1),
    kind: types.optional(types.enumeration(['long', 'short']), 'long'),
    trade_no: types.optional(types.number, 1),
    owner: types.optional(types.string, ''),
  })
  .views((self) => {
    return {
      get config_fields() {
        return {
          config_1: [
            'entry',
            'stop',
            'size',
            'risk',
            'increase',
            'use_default',
          ],
        };
      },
      get parent(): ISolvedTraderConfig {
        return getParent(self, 1);
      },
      get futureConfig(): IFutureInstance {
        return this.parent.futureCTrader;
      },
      get startEntryDefaultValue() {
        const startEntry = this.withAvg[0]?.entry;
        return startEntry || 0;
      },
      get averageEntryDefaultValue() {
        const avgEntry = this.withAvg.at(-1)?.avg_entry;
        return avgEntry || 0;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let result = getParamForField(self, configs, field, isGroup);

        if (['entry', 'stop'].includes(field)) {
          const futureCTrader = this.futureConfig;
          let value = futureCTrader.getTradingZones({
            kind: self.kind,
            use_fibonacci: true,
            no_of_trades: futureCTrader.config.zone_risk,
          });
          value = value.map((o) => {
            if (self.kind === 'long') {
              return {
                entry: Math.max(o.entry, o.stop),
                stop: Math.min(o.entry, o.stop),
              };
            }
            return {
              entry: Math.min(o.entry, o.stop),
              stop: Math.max(o.entry, o.stop),
            };
          });
          result = {
            ...result,
            kind: 'select',
            options: value.map((o, i) => {
              return {
                label: field === 'entry' ? o.entry : o.stop,
                value: field === 'entry' ? o.entry : o.stop,
              };
            }),
          };
        }
        if (field === 'size') {
          const avg_size = this.withAvg[0]?.avg_size;
          result = {
            ...result,
            value: avg_size,
          };
        }
        return result;
      },
      get withAvg() {
        let props = {};
        if (self.use_default) {
          let _tempTrades = this.buildConfig({});
          props = {
            entry: _tempTrades.at(-1).entry,
            trade_no: _tempTrades.length,
          };
        }
        const trades = this.buildConfig(props);
        return this.parent.withAvg({
          // zone_risk: 5,
          trades,
          kind: self.kind,
          entry: self.entry,
        });
      },
      buildConfig({
        raw_instance,
        trade_no,
        entry,
      }: {
        raw_instance?: boolean;
        trade_no?: number;
        entry?: number;
      }) {
        return this.parent.buildConfig({
          raw_instance,
          risk_per_trade: self.risk,
          // zone_risk: 5,
          trade_no: trade_no || self.trade_no, // this is updated from the result of the web-worker
          entry: entry || self.entry,
          stop: self.stop,
          kind: self.kind,
          increase: self.increase,
        });
      },
    };
  })
  .actions((self) => {
    const determineOptimumReward = flow(function* determineOptimumReward() {
      let { entry, stop, kind } = self;
      console.log('determineOptimumReward', entry, stop);
      if (entry && stop) {
        const { result } = yield self.parent.determineOptimumReward({
          kind: kind,
          entry,
          stop,
        });
        self.trade_no = result;
      }
    });
    function viewTrades(account: string) {
      self.parent.updateFutureConfig({
        support: self.futureConfig.config.support,
        resistance: self.futureConfig.config.resistance,
        entry: self.entry,
        kind: self.kind,
        stop: self.stop,
        risk_reward: self.trade_no,
        increase_position: self.increase,
        risk_per_trade: self.risk,
        use_fibonacci: true,
        sub_accounts: [account],
        currentEntry: self.entry,
        take_profit: self.entry,
      });
    }
    return {
      viewTrades,
      determineOptimumReward,
      updateFields(obj: any) {
        if (obj.entry) {
          obj.entry = parseFloat(obj.entry);
        }
        if (obj.stop) {
          obj.stop = parseFloat(obj.stop);
        }
        if (obj.risk) {
          obj.risk = parseFloat(obj.risk);
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
        // call the webworker to determine the optimum reward
        // if (self.entry && self.stop) {

        // }
      },
    };
  });

export const EntryZoneConfig = types
  .model('EntryZoneConfig', {
    entry: types.optional(types.number, 0),
    stop: types.optional(types.number, 0),
    size: types.optional(types.number, 0),
    risk: types.optional(types.number, 0),
    increase: types.optional(types.boolean, true),
    use_default: types.optional(types.boolean, false),
    // this doesn't display directly on the form.
    zone_index: types.optional(types.number, -1),
    kind: types.optional(types.enumeration(['long', 'short']), 'long'),
    trade_no: types.optional(types.number, 1),
  })
  .views((self) => {
    return {
      get config_fields() {
        return {
          config_1: [
            'entry',
            'stop',
            'size',
            'risk',
            'increase',
            'use_default',
          ],
        };
      },
      get activeZoneConfig(): IActiveZoneConfig {
        return getParent(self, 1);
      },
      get parent(): ISolvedTraderConfig {
        return getParent(self, 2);
      },
      get futureConfig(): IFutureInstance {
        return this.parent.futureCTrader;
      },
      get startEntryDefaultValue() {
        const startEntry = this.withAvg[0]?.entry;
        return startEntry || 0;
      },
      get averageEntryDefaultValue() {
        const avgEntry = this.withAvg.at(-1)?.avg_entry;
        return avgEntry || 0;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let result = getParamForField(self, configs, field, isGroup);

        if (['entry', 'stop'].includes(field)) {
          let value = this.activeZoneConfig.entries;
          console.log('value', value);
          result = {
            ...result,
            kind: 'select',
            options: value
              ?.sort((a, b) =>
                self.kind === 'long' ? a.entry - b.entry : b.stop - a.stop,
              )
              ?.filter((o) => {
                if (field === 'entry' && self.stop) {
                  if (self.kind === 'long') {
                    return o.entry > self.stop;
                  }

                  return o.entry < self.stop;
                }
                if (field === 'stop' && self.entry) {
                  if (self.kind === 'long') {
                    return o.stop < self.entry;
                  }
                  return o.stop > self.entry;
                }
                return true;
              })
              .map((o, i) => {
                let v;
                if (field === 'entry' && self.kind === 'long') {
                  v = o.entry;
                }
                if (field === 'stop' && self.kind === 'long') {
                  v = o.stop;
                }
                if (field === 'entry' && self.kind === 'short') {
                  v = o.entry;
                }
                if (field === 'stop' && self.kind === 'short') {
                  v = o.stop;
                }
                // let v = field === 'entry' ? o.entry : o.stop;
                return {
                  label: v,
                  value: v,
                };
              }),
          };
        }
        if (field === 'size') {
          const avg_size = this.withAvg[0]?.avg_size;
          result = {
            ...result,
            value: avg_size,
          };
        }
        return result;
      },
      get withAvg() {
        let props = {};
        if (self.use_default) {
          let _tempTrades = this.buildConfig({});
          props = {
            entry: _tempTrades.at(-1).entry,
            trade_no: _tempTrades.length,
          };
        }
        const trades = this.buildConfig(props);
        return self.parent.withAvg({
          trades,
          kind: self.kind,
          entry: self.entry,
        });
      },
      buildConfig({
        raw_instance,
        trade_no,
        entry,
      }: {
        raw_instance?: boolean;
        trade_no?: number;
        entry?: number;
      }) {
        return self.parent.buildConfig({
          raw_instance,
          risk_per_trade: self.risk,
          trade_no: trade_no || self.trade_no, // this is updated from the result of the web-worker
          entry: entry || self.entry,
          stop: self.stop,
          kind: self.kind,
          increase: self.increase,
        });
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        if (obj.entry) {
          obj.entry = parseFloat(obj.entry);
        }
        if (obj.stop) {
          obj.stop = parseFloat(obj.stop);
        }
        if (obj.risk) {
          obj.risk = parseFloat(obj.risk);
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
        // call the webworker to determine the optimum reward
        // if (self.entry && self.stop) {

        // }
      },
    };
  });

export const ActiveZoneConfig = types
  .model('ActiveZoneConfig', {
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
    kind: types.optional(types.enumeration(['long', 'short']), 'long'),
    entry_config: types.optional(EntryZoneConfig, {}),
    no_of_trades: types.optional(types.number, 3),
  })
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      get parent(): ISolvedTraderConfig {
        return getParent(self, 1);
      },
      get entries() {
        // this is to be used as the options for both entry and stop.
        let kind = self.entry_config.kind;
        let entry = kind === 'long' ? self.resistance : self.support;
        let stop = kind === 'long' ? self.support : self.resistance;
        const result = this.parent.buildConfig({
          entry,
          stop,
          kind,
          trade_no: self.no_of_trades,
          support: self.support,
          resistance: self.resistance,
          increase: false,
        });
        return result;
      },

      get zones(): Array<{
        support: number;
        resistance: number;
      }> {
        if (self.support && self.resistance) {
          let value = this.parent.futureCTrader
            .getTradingZones({
              kind: self.kind,
            })
            .sort((a, b) => a.entry - b.entry); // in ascending order
          const supportIndex = value.findIndex((o) =>
            self.kind === 'long'
              ? o.stop === self.support
              : o.entry === self.support,
          );
          const resistanceIndex = value.findIndex((o) =>
            self.kind === 'long'
              ? o.entry === self.resistance
              : o.stop === self.resistance,
          );
          const pairDiff = Math.abs(supportIndex - resistanceIndex);
          let supportIndices =
            supportIndex === 0 ? [] : createArray(supportIndex, 0, -1);
          let resistanceIndices =
            resistanceIndex === value.length - 1
              ? []
              : createArray(resistanceIndex, value.length - 1, 1);
          let supportFromResistanceIndices = resistanceIndices.map(
            (o) => o - pairDiff,
          );
          let mergedIndices = Array.from(
            new Set([...supportIndices, ...supportFromResistanceIndices]),
          );
          let zones = mergedIndices.map((o) => {
            let result = {
              support: value[o].stop,
              resistance: value[o + pairDiff].entry,
            };
            if (self.kind === 'short') {
              result = {
                support: value[o].entry,
                resistance: value[o + pairDiff].stop,
              };
            }
            return result;
          });
          console.log('zones', zones);
          return zones;
        }
        return [];
      },
      get zoneOptions() {
        return this.zones.map((i) => `${i.support} - ${i.resistance}`);
      },
      get currentZone() {
        return self.entry_config.zone_index > -1
          ? this.zoneOptions[self.entry_config.zone_index]
          : null;
      },
      get isValid() {
        return self.support && self.resistance;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let parent: ISolvedTraderConfig = getParent(self, 1);
        let result = getParamForField(self, configs, field, isGroup);
        if (['support', 'resistance'].includes(field)) {
          let value = parent.futureCTrader.getTradingZones({ kind: self.kind });
          result = {
            ...result,
            kind: 'select',
            options: value
              .sort((a, b) => a.entry - b.entry)
              .filter((o) => {
                if (field === 'resistance' && self.support) {
                  return o.stop >= self.support;
                }
                if (field === 'support' && self.resistance) {
                  if (self.kind === 'long') {
                    return o.stop < self.resistance;
                  }

                  return o.entry < self.resistance;
                }
                if (field === 'resistance' && !self.support) {
                  let support = Math.min(...value.map((o) => o.stop));
                  if (self.kind === 'long') {
                    return o.entry > support;
                  }
                }
                return true;
              })
              .map((o, i) => {
                let v;
                if (field === 'resistance' && self.kind === 'long') {
                  v = o.entry;
                }
                if (field === 'support' && self.kind === 'long') {
                  v = o.stop;
                }
                if (field === 'resistance' && self.kind === 'short') {
                  v = o.stop;
                }
                if (field === 'support' && self.kind === 'short') {
                  v = o.entry;
                }
                return {
                  label: v,
                  value: v,
                };
              }),
          };
        }

        return result;
      },
    };
  })
  .actions((self) => {
    function updateFields(obj: any) {
      if (obj.support) {
        obj.support = parseFloat(obj.support);
      }
      if (obj.resistance) {
        obj.resistance = parseFloat(obj.resistance);
      }
      applySnapshot(self, { ...getSnapshot(self), ...obj });
    }
    function updateSupportAndResistance(zoneOptionValue: string) {
      if (self.currentZone) {
        let [support, resistance] = self.currentZone.split(' - ');
        self.support = parseFloat(support);
        self.resistance = parseFloat(resistance);
        // self.entry_config.updateFields({
        //   entry: 0,
        //   stop: 0,
        // });
        // onUpdateZone(zoneOptionValue);
      }
    }
    function onUpdateZone(zoneOptionValue: string) {
      let index = self.zoneOptions.indexOf(zoneOptionValue);
      self.entry_config.updateFields({
        zone_index: index,
      });
    }
    function updateTradeNo(result: number) {
      self.entry_config.updateFields({
        trade_no: result,
      });
    }
    const determineOptimumReward = flow(function* determineOptimumReward() {
      let { entry, stop, kind } = self.entry_config;
      console.log('determineOptimumReward', entry, stop);
      if (entry && stop) {
        const { result } = yield self.parent.determineOptimumReward({
          kind: kind,
          entry,
          stop,
        });
        self.entry_config.updateFields({
          trade_no: result,
        });
      }
    });
    function viewTrades(account: string) {
      self.parent.updateFutureConfig({
        support: self.support,
        resistance: self.resistance,
        entry: self.entry_config.entry,
        kind: self.entry_config.kind,
        stop: self.entry_config.stop,
        risk_reward: self.entry_config.trade_no,
        increase_position: self.entry_config.increase,
        risk_per_trade: self.entry_config.risk,
        use_fibonacci: true,
        sub_accounts: [account],
        currentEntry: self.entry_config.entry,
        take_profit: self.entry_config.entry,
      });
    }
    return {
      viewTrades,
      determineOptimumReward,
      updateTradeNo,
      onUpdateZone,
      updateFields,
      updateSupportAndResistance,
    };
  });

const Collateral = types
  .model('Collateral', {
    amount: types.optional(types.number, 0),
    currency: types.optional(types.string, ''),
    places: types.optional(types.number, 2),
  })
  .views((self) => {
    return {
      get display() {
        return self.currency.toUpperCase() === 'USDT'
          ? `$${self.amount.toFixed(self.places)}`
          : `${self.amount.toFixed(
              self.places,
            )} ${self.currency.toUpperCase()}`;
      },
      formatValue(value: number) {
        return self.currency.toUpperCase() === 'USDT'
          ? `$${value.toFixed(self.places)}`
          : `${value.toFixed(self.places)} ${self.currency.toUpperCase()}`;
      },
    };
  });

export const SolvedTraderConfig = types
  .model('SolvedTraderConfig', {
    currentAccountId: types.optional(types.number, -1),
    base_collateral: types.optional(Collateral, {}),
    collateral: types.optional(Collateral, {}),
    loan: types.optional(Collateral, {}),
    quote_loan: types.optional(Collateral, {}),
    coin: types.optional(types.string, 'BTC'),
    symbol: types.optional(types.string, 'BTCUSDT'),
    config: types.optional(MainTradeConfig, {}),
    margin_config: types.optional(MarginOrder, {}),
    future_config: types.optional(FutureOrder, {}),
    loading: types.optional(types.boolean, false),
    futureCTrader: types.optional(FutureInstance, {}),
    tradeZones: types.optional(types.array(TradeZoneStore), []),
    owner: types.optional(types.string, ''),
    accounts: types.optional(types.array(MarketTypeInstances), []),
    new_account: types.optional(MarketTypeInstances, newAccountDefault),
    active_zone: types.optional(ActiveZoneConfig, {}),
    ai: types.optional(AIStore, {}),
    exchanges: types.optional(types.array(ExchangeStore), []),
    clonedStore: types.optional(FutureInstance, {}),
    entry_config: types.optional(NewEntryZoneConfig, {}),
  })
  .views((_self: any) => {
    const self = _self;
    return {
      get current_exchange() {
        return self.exchanges[self.currentAccountId];
      },
      get marginLevel() {
        if (self.loan.amount === 0) {
          return 999;
        }
        const result =
          (this.collateralBalance + this.loanBalance) / this.loanBalance;
        return result;
      },
      get collateralBalance() {
        return (
          self.collateral.amount + this.collateralBaseBalance - this.loanBalance
        );
      },
      get collateralBaseBalance() {
        return (
          self.base_collateral.amount / self.futureCTrader.profit_base_price
        );
      },
      get loanBalance() {
        const working_dollar_value = self.futureCTrader.profit_base_price;
        return self.loan.amount * working_dollar_value + self.quote_loan.amount;
      },
      get loanBaseBalance() {
        return this.loanBalance / self.futureCTrader.profit_base_price;
      },
      get profitMade() {
        const currentLoan = self.quote_loan.amount;
        const startingLoan = self.futureCTrader.profit_quote_price;
        return Math.abs(currentLoan - startingLoan);
      },
      getSupportGroup(support: number) {
        const groups = groupBy(self.tradeZones, 'support');
        return groups[support] || [];
      },
      get zones() {
        let start = -12;
        let breakEvenPrice = this.breakEvenPrice;
        return new Array(24).fill(0).map((o, i) => {
          let index = start + i;
          return parseFloat(
            (
              breakEvenPrice * Math.pow(1 + self.config.percent / 100, index)
            ).toFixed(2),
          );
        });
      },
      get breakEvenPrice() {
        if (self.collateral.currency !== 'USDT') {
          return (self.loanBalance * 2) / self.collateral.amount;
        }
        return self.collateral.amount / self.loanBalance;
      },
      get activeAccount() {
        const account = self.accounts.find(
          (_: any, i: number) => i === self.currentAccountId,
        );
        return account;
      },

      get marginAccount() {
        const account = self.accounts.find((o: IMarketTypeInstance) =>
          ['cross', 'isolated_margin', 'margin'].includes(o.market_type),
        );
        return account;
      },
      get futureAccount() {
        const account = self.accounts.find(
          (o: IMarketTypeInstance) => o.market_type === 'future',
        );
        return account;
      },

      get loanPaid() {
        return self.loan.amount - self.loanBalance;
      },

      get stats(): Array<{
        market_type: 'future' | 'margin';
        [key: string]: any;
      }> {
        //Todo: There could be more than one futures account. Need to handle that
        const marginAccount = this.marginAccount?.stats || [];
        const futureAccount = this.futureAccount?.stats || [];
        return [...marginAccount, ...futureAccount];
      },
      withAvg({
        trades,
        kind,
        entry,
        zone_risk,
      }: {
        zone_risk?: number;
        trades: any[];
        kind: 'long' | 'short';
        entry: number;
      }) {
        console.log('entry', entry, 'trades', trades);
        return self.futureCTrader.update_open_prices({
          trades,
          kind,
          currentEntry: entry,
          take_profit: entry,
        });
      },
      buildConfig({
        raw_instance,
        risk_per_trade,
        trade_no,
        entry,
        stop,
        zone_risk,
        kind,
        increase = true,
        support,
        resistance,
      }: {
        support?: number;
        resistance?: number;
        increase?: boolean;
        raw_instance?: boolean;
        risk_per_trade?: number;
        trade_no?: number;
        zone_risk?: number;
        entry: number;
        stop: number;
        kind: 'long' | 'short';
      }) {
        const risk = risk_per_trade || self.futureCTrader.config.risk_per_trade;
        const parentConfig = self.futureCTrader.config.toJSON();
        let _entry = entry;
        let _stop = stop;
        const config = {
          ...parentConfig,
          risk_reward: trade_no || self.futureCTrader.config.risk_reward,
          price_places: self.futureCTrader.price_places,
          decimal_places: self.futureCTrader.decimal_places,
          take_profit: _entry,
          increase_position: increase,
          support: support || self.futureCTrader.config.support,
          resistance: resistance || self.futureCTrader.config.resistance,
          entry: _entry,
          stop: _stop,
        } as SignalConfigType & {
          support: number;
          resistance: number;
        };
        if (zone_risk) {
          config.zone_risk = zone_risk;
        }
        const instance = new Signal(config);
        console.log('tp', instance.take_profit);
        console.log('config', config);
        if (raw_instance) {
          return instance;
        }
        const condition =
          (kind === 'long'
            ? _entry > config.support
            : _entry >= config.support) && _stop >= config.support * 0.999; // when stop is slightly lower than support
        const result =
          _entry === _stop
            ? []
            : condition
            ? instance.build_entry({
                current_price: _entry,
                stop_loss: _stop,
                risk,
                kind: kind,
                no_of_trades: trade_no,
              }) || []
            : [];
        return result;
      },
    };
  })
  .actions((_self: any) => {
    const _this = _self;
    const self = _self;
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const addNewMarketType = () => {};
    const saveConfig = flow(function* saveConfig(method: string) {});
    function updateTradeRiskReward(entry: number, result: number) {
      const instance = self.tradeZones.find((o) => o.entry === entry);
      if (instance) {
        instance.updateTradeNo(result);
      }
    }
    const fetchExchanges = flow(function* fetchExchanges() {
      try {
        const result = yield adapter.getExchanges();
        self.exchanges = cast(result);
      } catch (error) {
        throw error;
      }
    });
    const createNewAccount = flow(function* createNewAccount() {
      self.loading = true;
      try {
        yield adapter.addNewControlledAccount(_this.new_account.accountDetails);
        _this.accounts.push(_this.new_account.accountDetails);
        _this.new_account.reset();
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const onFetchAccounts = flow(function* onFetchAccounts(params?: {
      owner: string;
      market_type: string;
    }) {
      self.loading = true;
      if (self.owner && self.symbol) {
        try {
          let { accounts, collateral, loan, base_collateral, quote_loan } =
            yield adapter.getOrdersForAccount({
              owner: self.owner,
              symbol: self.symbol,
            });
          _this.collateral = cast(collateral);
          _this.loan = cast(loan);
          _this.base_collateral = cast(base_collateral);
          _this.quote_loan = cast(quote_loan);
          if (params?.market_type) {
            const foundAccount = accounts.find(
              (o: any) =>
                o.owner === params.owner &&
                o.market_type === params.market_type,
            );
            self.loading = false;
            return foundAccount;
          } else {
            _this.accounts = cast(accounts);
          }
          self.loading = false;
        } catch (error) {
          console.log(error);
          self.loading = false;
          throw error;
        }
      }
    });
    const buildTradeZones = () => {
      const supportResistanceRiskReward = 15;
      // update the risk reward on the future config
      self.futureCTrader.config.updateFields({
        zone_risk: supportResistanceRiskReward,
      });
      const long_zones = self.futureCTrader.getTradingZones({ kind: 'long' });
      const short_zones = self.futureCTrader.getTradingZones({ kind: 'short' });
      const ratio = Math.abs(short_zones[0].entry / short_zones[1].entry);
      const updated_zones = long_zones.map((o, i) => {
        const zone_index = Math.floor(i / 3);
        const min_short = short_zones
          .filter((i) => o.entry >= i.entry)
          .map((i) => i.entry);
        let max = Math.max(...min_short);
        let support_index = zone_index * 3;
        let stop = o.stop;
        return {
          entry: o.entry,
          stop: stop,
          opposite_entry: max,
          kind: 'long',
          opposite_stop: to_f(max * ratio ** 2, '%.1f'),
          support: long_zones[support_index].stop,
          resistance: (long_zones[support_index + 2] || long_zones.at(-1))
            ?.entry,
        };
      });
      console.log('updated_zones', updated_zones);
      let groups = groupBy(updated_zones, 'support');
      updated_zones.forEach((element) => {
        element.stops = groups[element.support].map((o) => o.stop);
      });
      self.tradeZones = cast([
        ...updated_zones,
        // ...short_zones.map((o) => ({
        //   support: o.entry,
        //   resistance: o.stop,
        //   kind: 'short',
        // })),
      ]);
      // self.tradeZones[7].buildEntryZones();
      // debugger;
    };
    const determineOptimumRisk = ({
      kind,
      entry,
      stop,
    }: {
      kind: 'long' | 'short';
      entry: number;
      stop: number;
    }) => {
      const config = {
        ...self.futureCTrader.config.toJSON(),
        kind,
        entry,
        stop,
      };
      return adapter.getOptimumRisk(config, self.futureCTrader.max_size);
    };

    const determineOptimumReward = ({
      kind,
      entry,
      stop,
      zone_risk,
    }: {
      kind: 'long' | 'short';
      entry: number;
      stop: number;
      zone_risk?: number;
    }) => {
      let additional = zone_risk ? { zone_risk } : {};
      const config = {
        ...self.futureCTrader.config.toJSON(),
        kind,
        entry,
        stop,
        ...additional,
      };
      return adapter.getOptimumReward(config);
    };
    function setCurrentAccount(index: number) {
      self.currentAccountId = index;
    }
    function updateFutureConfig(config: any) {
      let _config = { ...getSnapshot(self.futureCTrader), symbol: self.symbol };
      delete _config.config;
      self.clonedStore.updateState(_config);
      self.clonedStore.updateConfig(config);
    }
    const onAddNewAccount = ({ selectedAccount, selectedKind }) => {
      console.log('Clicked from store', selectedAccount, selectedKind);
    };
    const updateTradeNo = (value: number) => {
      console.log('received', value);
      self.entry_config.updateFields({
        trade_no: value,
      });
    };
    return {
      determineOptimumRisk,
      updateTradeNo,
      updateFutureConfig,
      setCurrentAccount,
      determineOptimumReward,
      updateTradeRiskReward,
      buildTradeZones,
      onFetchAccounts,
      addNewMarketType,
      saveConfig,
      createNewAccount,
      fetchExchanges,
      onAddNewAccount,
    };
  });

export default SolvedTraderConfig;

export interface ISolvedTraderConfig
  extends Instance<typeof SolvedTraderConfig> {}

export interface IActiveZoneConfig extends Instance<typeof ActiveZoneConfig> {}
export interface INewEntryZoneConfig
  extends Instance<typeof NewEntryZoneConfig> {}
export interface MobileTradeEditSection
  extends Instance<typeof MobileTradeEditSection> {}
