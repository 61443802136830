import {
  Instance,
  flow,
  getEnv,
  getParent,
  getSnapshot,
  types,
} from 'mobx-state-tree';
import { BotAdapterType } from '../../adapter';
import { generateUniqueString } from '../utils';
import { IFutureInstance } from '../futureConfig';
import { ISolvedTraderConfig } from './rootStore';

export const TradeZoneStore = types
  .model('TradeZone', {
    entry: types.number,
    stop: types.number,
    support: types.number,
    resistance: types.number,
    kind: types.enumeration(['long', 'short']),
    opposite_entry: types.optional(types.number, 0),
    opposite_stop: types.optional(types.number, 0),
    trade_no: types.optional(types.number, 1),
    stops: types.optional(types.array(types.number), []),
    risk: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get parent() {
        let parent: ISolvedTraderConfig = getParent(self, 2);
        return parent;
      },
      get futureConfig(): IFutureInstance {
        return this.parent.futureCTrader;
      },
      getOptions(): number[] {
        const stops = self.stops;
        if (self.kind === 'long') {
          return stops.filter((o) => o < self.entry);
        }
        return stops;
      },
      get risk_per_trade() {
        return self.risk ? self.risk : this.futureConfig.config.risk_per_trade;
      },
      buildConfig({ raw_instance }: { raw_instance?: boolean }) {
        return self.parent.buildConfig({
          raw_instance,
          risk_per_trade: self.risk,
          trade_no: self.trade_no,
          entry: self.entry,
          stop: self.stop,
          kind: self.kind,
        });
      },
      get take_profit() {
        return this.withAvg[0]?.sell_price;
      },
      get avg_entry() {
        return this.withAvg[0]?.avg_entry;
      },
      get avg_size() {
        return this.withAvg[0]?.avg_size;
      },
      get withAvg() {
        const trades = this.buildConfig({});
        return self.parent.withAvg({
          trades,
          kind: self.kind,
          entry: self.entry,
        });
      },
    };
  })
  .actions((self) => {
    const updateTradeNo = (trade_no: number) => {
      self.trade_no = trade_no;
      // const trades = self.buildConfig({});
      console.log('trades', self.withAvg);
    };
    const buildEntryZones = flow(function* buildEntryZones() {
      try {
        if (self.entry && self.stop) {
          const { result } = yield self.parent.determineOptimumReward({
            kind: self.kind,
            entry: self.entry,
            stop: self.stop,
          });
          updateTradeNo(result);
        }
      } catch (error) {
        console.log('yield-error', error);
      }
    });
    return {
      afterCreate() {
        buildEntryZones();
      },
      updateTradeNo,
      buildEntryZones,
      updateStop(newStop: number) {
        self.stop = newStop;
        buildEntryZones();
      },
      setRisk(newRisk: number) {
        self.risk = newRisk;
      },
    };
  });
const MessageStore = types.model('MessageStore', {
  type: types.string,
  content: types.string,
  loading: types.optional(types.boolean, false),
});
export const AIStore = types
  .model('AIStore', {
    sessionId: types.optional(types.string, generateUniqueString()),
    messages: types.optional(types.array(MessageStore), []),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get allMessages() {
        let messages = [...getSnapshot(self.messages)];
        if (self.loading) {
          messages.push({ type: 'ai', content: '...', loading: true });
        }
        return messages;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const processMessage = flow(function* (message: string) {
      self.messages.push({ type: 'human', content: message });
      try {
        self.loading = true;
        const { messages } = yield adapter.getAIResponse({
          message,
          messages: getSnapshot(self.messages),
          sessionId: self.sessionId,
        });
        console.log('messages', messages);
        self.loading = false;
        self.messages = messages;
      } catch (error) {
        self.loading = false;
      }
    });
    return {
      processMessage,
    };
  });

export interface ITradeZoneStore extends Instance<typeof TradeZoneStore> {}
export interface IAIStore extends Instance<typeof AIStore> {}
