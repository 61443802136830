import { Signal, SignalConfigType } from './trade_signal';
import {
  computeTotalAverageForEachTrade,
  determine_average_entry_and_size,
} from './utils';
import pnl from './pnl';

export {
  computeTotalAverageForEachTrade,
  createArray,
  determine_average_entry_and_size,
} from './utils';

export type AppConfig = {
  fee: number;
  risk_per_trade: number;
  risk_reward: number;
  focus: number;
  budget: number;
  support: number;
  resistance: number;
  percent_change: number;
  tradeSplit?: number;
  take_profit?: number;
  kind: 'long' | 'short';
  entry: number;
  stop: number;
  min_size: number;
  price_places?: string;
  strategy?: 'quantity' | 'entry';
  as_array?: boolean;
  decimal_places?: string;
  min_profit?: number;
  raw?: boolean;
};

export type ExtendConfigType = {
  take_profit?: number;
  entry: number;
  risk?: number;
  stop?: number;
  risk_reward?: number;
  raw_instance?: boolean;
  no_of_trades?: number;
  increase?: boolean;
  price_places?: string;
  decimal_places?: string;
  kind?: 'long' | 'short';
};
export function buildConfig(
  app_config: AppConfig,
  {
    take_profit,
    entry,
    stop,
    raw_instance,
    risk,
    no_of_trades,
    risk_reward,
    kind,
    increase,
    price_places = '%.1f',
    decimal_places = '%.3f',
  }: ExtendConfigType,
) {
  let fee = app_config.fee / 100;
  let working_risk = risk || app_config.risk_per_trade;
  let trade_no = no_of_trades || app_config.risk_reward;
  const config: SignalConfigType = {
    focus: app_config.focus,
    fee: fee,
    budget: app_config.budget,
    risk_reward: risk_reward || trade_no,
    support: app_config.support,
    resistance: app_config.resistance,
    price_places: app_config.price_places || price_places,
    decimal_places: decimal_places,
    percent_change: app_config.percent_change / app_config.tradeSplit,
    risk_per_trade: working_risk,
    take_profit: take_profit || app_config.take_profit,
    increase_position: increase,
    minimum_size: app_config.min_size,
    entry,
    stop,
    kind:app_config.kind
  };
  const instance = new Signal(config);
  if (raw_instance) {
    return instance;
  }
  if (!stop) {
    return [];
  }
  const condition =
    (kind === 'long'
      ? entry > app_config.support
      : entry >= app_config.support) && stop >= app_config.support * 0.999; // when stop is slightly lower than support
  const result =
    entry === stop
      ? []
      : condition
      ? instance.build_entry({
          current_price: entry,
          stop_loss: stop,
          risk: working_risk,
          kind: kind || (app_config.kind as any),
          no_of_trades: trade_no,
        }) || []
      : [];
  return computeTotalAverageForEachTrade(result, config);
  return result;
}

export function buildAvg({
  _trades,
  kind,
}: {
  _trades: any[];
  kind: 'long' | 'short';
}) {
  let avg: any = determine_average_entry_and_size(
    _trades?.map((r: any) => ({
      price: r.entry,
      quantity: r.quantity,
    })) || [],
  );
  const stop_prices = _trades.map((o) => o.stop);
  const stop_loss =
    kind === 'long' ? Math.min(...stop_prices) : Math.max(...stop_prices);
  avg.pnl = pnl.determine_pnl(avg.price, stop_loss, avg.quantity, kind);
  return avg;
}
