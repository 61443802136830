import { getConnections, disconnectAllStreams } from './priceSockets';
import loadApiHelper, { TradeType } from './apiHelper';
import type { Fetcher, OpenTradeType } from './types';

const connections: { [key: string]: WebSocket } = {};
export interface BotAdapterType {
  getOrdersForAccount(
    params: any,
  ): Promise<{ margin_orders: any[]; future_orders: any[] }>;
  createOrdersForAccount: (
    config: any,
    account: 'future' | 'margin',
  ) => Promise<any>;
  addNewControlledAccount: (params: any) => Promise<any>;
  createSingleControlledOrder: (params: any) => Promise<any>;
  placeFutureOrder: (params: any, kind: any) => Promise<any>;
  fetchAllMarkets: (owner: string) => Promise<any>;
  getBotAccounts: () => Promise<any[]>;
  addNewSymbol: (owner: string, symbol: string) => Promise<void>;
  deleteSymbol: (owner: string, symbol: string) => Promise<any[]>;
  updateConfig: (owner: string, config: any) => Promise<void>;
  bulkTrades: (owner: string, symbol: string, trades: any[]) => any;
  updateStopTrade: (owner: string, symbol: string, order: any) => Promise<any>;
  updateOrders: (owner: any, symbol: string, action?: boolean) => Promise<void>;
  createJob: (params: any) => Promise<any>;
  deleteJob: (params: any) => Promise<any>;
  editJob: (params: any) => Promise<any>;
  toggleJobsWithAction: (params: any) => Promise<any>;
  getJobStatus: (job_id: string) => Promise<any>;
  createProfile: (params: any) => Promise<any>;
  editProfile: (params: any) => Promise<any>;
  deleteProfile: (params: any) => Promise<any>;
  runProfile: (params: any) => Promise<any>;
  bulkJobAction: (params: any) => Promise<any>;
  removeOpenOrders: (params: any) => Promise<any>;
  pauseJob: (params: any) => Promise<any>;
  runJob: (params: any) => Promise<any>;
  closePosition: (params: any) => Promise<any>;
  loadInitialData: (
    symbol: string,
    kind?: 'long' | 'short',
    rootPath?: any,
  ) => Promise<{
    pip: number;
    balance: number;
    openTrade?: OpenTradeType;
    allMarkets: string[];
  }>;
  initializeSockets: (
    socket: WebSocket | undefined,
    streams: Array<string>,
    callback: any,
  ) => any;
  disconnectAllStreams: () => void;
  removeSymbolFromBackground: (owner: string, symbol: string) => Promise<any>;
  addSymbolToBackground: (owner: string, symbol: string) => Promise<any>;
  getSpotSymbols: (params: any) => Promise<any>;
  createSpotProfile: (params: any) => Promise<any>;
  deleteSpotProfile: (params: any) => Promise<any>;
  runSpotProfile: (params: any) => Promise<any>;
  tempPauseMarket: (params: any) => Promise<any>;
  updateTPSL: (params: any) => Promise<any>;
  updateProtectProfit: (params: any) => Promise<any>;
  analyzePosition: (params: any) => Promise<any>;
  placeSignalTrade: (params: {
    symbol: string;
    kind: 'long' | 'short';
    cancel?: boolean;
    append?: boolean;
    orders: any[];
    sub_accounts: string[];
  }) => Promise<any>;
  placeStopOrders: (params: {
    symbol: string;
    orders: any[];
    owner: string;
  }) => Promise<any>;
  updateClosePrices: (params: {
    symbol: string;
    kind: 'long' | 'short';
    orders: any[];
    sub_accounts: string[];
    reduce?: boolean;
  }) => Promise<any>;
  updateMarginClosePrices: (params: {
    symbol: string;
    kind: 'long' | 'short';
    size: number;
    take_profit: number;
  }) => Promise<any>;
  placeMarginSignalTrade: (params: {
    symbol: string;
    size: number;
    entry: number;
    stop: number;
    kind: 'long' | 'short';
    cancel?: boolean;
    owner: string;
  }) => Promise<any>;
  reducePositionTrade: (params: {
    symbol: string;
    kind: 'long' | 'short';
    owner: string;
  }) => Promise<any>;

  getAIResponse: (params: {
    message: string;
    messages: any[];
    sessionId: string;
  }) => Promise<any>;
  getExchanges: (params:any) => Promise<any>;
  getExchangePosition: (params: any) => Promise<any>;
  purchase_bnb: (params: any) => Promise<any>;
  transfer_usdt: (params: any) => Promise<any>;

  // worker related code
  getOptimumReward: (payload: {
    strategy?: 'quantity' | 'entry';
    [key: string]: any;
  }) => Promise<any>;
  getOptimumRisk: (
    payload: {
      strategy?: 'quantity' | 'entry';
      [key: string]: any;
    },
    max_size: number,
    multiplier?: number,
    gap?: number,
  ) => Promise<any>;
  getOptimumStop: (
    payload: {
      strategy?: 'quantity' | 'entry';
      [key: string]: any;
    },
    stop_target: number,
    gap: number,
  ) => Promise<any>;
  businessLogicActions: (params: {
    action: string;
    owner: string;
    symbol: string;
    config_owner?: string;
    data?: any;
  }) => Promise<any>;
  getExchange: (params: any) => Promise<any>;
  getTradeSections: (params: any) => Promise<any>;
  transferFunds: (params: any) => Promise<any>;
}
export type CallbackType = (price: number) => (evt: { data: any }) => void;
export const loadAdapter = (environment: 'testing' | 'production'): any => ({
  ...loadApiHelper(environment),
  disconnectAllStreams() {
    disconnectAllStreams(connections);
  },
  initializeSockets(
    socket: WebSocket | undefined,
    symbols: Array<string>,
    callback: (price: number) => any,
  ) {
    let _socket: WebSocket;
    if (socket) {
      this.disconnectAllStreams();
    }
    _socket = getConnections(symbols, connections);
    _socket.onmessage = (evt: { data: any }) => {
      const result = JSON.parse(evt.data);
      // const market = result.stream.replace("@ticker", "");
      const price = parseFloat(result.data.c);
      // console.log('currentPrice', price)
      callback(price);
    };
    return _socket;
  },
});

export function setAdapter(fetcher: Fetcher) {
  return {
    // getConnections(streams: Array<string>) {
    //     return getConnections(fetcher, streams, connections)
    // },
    initializeSockets(
      socket: WebSocket | undefined,
      symbols: Array<string>,
      callback: (price: number) => any,
    ) {
      return fetcher.initializeSockets(socket, symbols, callback);
    },
    disconnectAllStreams() {
      return fetcher.disconnectAllStreams();
    },
    loadInitialData: async (
      symbol: string,
      kind?: 'long' | 'short',
      rootPath?: string,
    ) => {
      return await fetcher.loadInitialData(symbol, kind, rootPath);
    },
    createNewTrade: async (params: TradeType) => {
      return await fetcher.createNewTrade(params);
    },
    updateStopLoss: async (params: any) => {
      return await fetcher.updateStopLoss(params);
    },
    updateTakeProfit: async (params: any) => {
      return await fetcher.updateTakeProfit(params);
    },
    marketCloseTrade: async (params: any) => {
      return await fetcher.marketCloseTrade(params);
    },
    bulkTrades: async (owner: string, symbol: string, trades: any[]) => {
      return await fetcher.bulkTrades(owner, symbol, trades);
    },
    increasePosition: async (params: any) => {
      return await fetcher.increasePosition(params);
    },
    getSwingInfo: async (params: any) => {
      return await fetcher.getSwingInfo(params);
    },
    getBotAccounts: async () => {
      return await fetcher.getBotAccounts();
    },
    updateConfig: async (owner: any, config: any) => {
      return await fetcher.updateConfig(owner, config);
    },
    updateOrders: async (owner: string, symbol: string, action?: boolean) => {
      return await fetcher.updateOrders(owner, symbol, action);
    },
    addSymbolToBackground: async (owner: string, symbol: string) => {
      return await fetcher.addSymbolToBackground(owner, symbol);
    },
    removeSymbolFromBackground: async (owner: string, symbol: string) => {
      return await fetcher.removeSymbolFromBackground(owner, symbol);
    },
    updateStopTrade: async (owner: string, symbol: string, order: any) => {
      return await fetcher.updateStopTrade(owner, symbol, order);
    },
    createJob: async (params: any) => {
      return await fetcher.createJob(params);
    },
    deleteJob: async (params: any) => {
      return await fetcher.deleteJob(params);
    },
    pauseJob: async (params: any) => {
      return await fetcher.pauseJob(params);
    },
    runJob: async (params: any) => {
      return await fetcher.runJob(params);
    },
    editJob: async (params: any) => {
      return await fetcher.editJob(params);
    },
    toggleJobsWithAction: async (params: any) => {
      return await fetcher.toggleJobsWithAction(params);
    },
    addNewSymbol: async (owner: string, symbol: string) => {
      return await fetcher.addNewSymbol(owner, symbol);
    },
    deleteSymbol: async (owner: string, symbol: string) => {
      return await fetcher.deleteSymbol(owner, symbol);
    },
    bulkJobAction: async (params: any) => {
      return await fetcher.bulkJobAction(params);
    },
    getJobStatus: async (job_id: string) => {
      return await fetcher.getJobStatus(job_id);
    },
    createProfile: async (params: any) => {
      return await fetcher.createProfile(params);
    },
    deleteProfile: async (params: any) => {
      return await fetcher.deleteProfile(params);
    },
    editProfile: async (params: any) => {
      return await fetcher.editProfile(params);
    },
    runProfile: async (params: any) => {
      return await fetcher.runProfile(params);
    },
    removeOpenOrders: async (params: any) => {
      return await fetcher.removeOpenOrders(params);
    },
    closePosition: async (params: any) => {
      return await fetcher.closePosition(params);
    },
    fetchAllMarkets: async (owner: string) => {
      return await fetcher.fetchAllMarkets(owner);
    },
    getSpotSymbols: async (params: any) => {
      return await fetcher.getSpotSymbols(params);
    },
    createSpotProfile: async (params: any) => {
      return await fetcher.createSpotProfile(params);
    },
    deleteSpotProfile: async (params: any) => {
      return await fetcher.deleteSpotProfile(params);
    },
    runSpotProfile: async (params: any) => {
      return await fetcher.runSpotProfile(params);
    },
    tempPauseMarket: async (params: any) => {
      return await fetcher.tempPauseMarket(params);
    },
    updateTPSL: async (params: any) => {
      return await fetcher.updateTPSL(params);
    },
    updateProtectProfit: async (params: any) => {
      return await fetcher.updateProtectProfit(params);
    },
    placeFutureOrder: async (params: any, kind?: string) => {
      return await fetcher.placeFutureOrder(params, kind);
    },
    createSingleControlledOrder: async (params: any) => {
      return await fetcher.createSingleControlledOrder(params);
    },
    addNewControlledAccount: async (params: any) => {
      return await fetcher.addNewControlledAccount(params);
    },
    getOrdersForAccount: async (params: any) => {
      return await fetcher.getOrdersForAccount(params);
    },
    createOrdersForAccount: async (
      config: any,
      account: 'future' | 'margin',
    ) => {
      return await fetcher.createOrdersForAccount(config, account);
    },
    analyzePosition: async (params: any) => {
      return await fetcher.analyzePosition(params);
    },
    placeSignalTrade: async (params: any) => {
      return await fetcher.placeSignalTrade(params);
    },
    placeStopOrders: async (params: any) => {
      return await fetcher.placeStopOrders(params);
    },
    updateClosePrices: async (params: any) => {
      return await fetcher.updateClosePrices(params);
    },
    updateMarginClosePrices: async (params: any) => {
      return await fetcher.updateMarginClosePrices(params);
    },
    placeMarginSignalTrade: async (params: any) => {
      return await fetcher.placeMarginSignalTrade(params);
    },
    getAIResponse: async (params: any) => {
      return await fetcher.getAIResponse(params);
    },
    reducePositionTrade: async (params: any) => {
      return await fetcher.reducePositionTrade(params);
    },
    getExchanges: async (params) => {
      return await fetcher.getExchanges(params);
    },
    purchase_bnb: async (params: any) => {
      return await fetcher.purchase_bnb(params);
    },
    transfer_usdt: async (params: any) => {
      return await fetcher.transfer_usdt(params);
    },
    getOptimumReward: async (payload: any) => {
      return await fetcher.getOptimumReward(payload);
    },
    getOptimumRisk: async (
      payload: any,
      max_size: number,
      multiplier?: number,
      gap?: number,
    ) => {
      return await fetcher.getOptimumRisk(payload, max_size, multiplier, gap);
    },
    getOptimumStop: async (payload: any, stop_target: number, gap: number) => {
      return await fetcher.getOptimumStop(payload, stop_target, gap);
    },
    businessLogicActions: async (params: {
      action: string;
      owner: string;
      symbol: string;
      config_owner: string;
      data?: any;
    }) => {
      return await fetcher.businessLogicActions(params);
    },
    getExchange: async (params: any) => {
      return await fetcher.getExchange(params);
    },
    getTradeSections: async (params: any) => {
      return await fetcher.getTradeSections(params);
    },
    transferFunds: async (params: any) => {
      return await fetcher.transferFunds(params);
    },
  };
}
