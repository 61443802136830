import {
  Instance,
  applySnapshot,
  getParent,
  getSnapshot,
  types,
} from 'mobx-state-tree';
import { getParamForField } from '../utils';
import { ISolvedTraderConfig } from './rootStore';

const configs = [
  {
    name: 'percent',
    label: 'Percent Increase(%)',
    kind: 'special',
  },
  { name: 'stop_percent', label: 'Stop % change', kind: 'special' },
  {
    name: 'future_usdt_min_size',
    label: 'Futures USDT quantity',
    kind: 'special',
  },
  {
    name: 'future_coin_min_size',
    label: 'Futures Coin quantity',
    kind: 'special',
  },
  { name: 'borrow', label: 'Borrow', kind: 'checkbox' },
  {
    name: 'kind',
    label: 'Kind',
    kind: 'select',
    options: ['long', 'short'],
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
  },
  {
    name: 'type',
    label: 'Type',
    kind: 'select',
    options: ['open', 'close'],
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
  },
  {
    name: 'zones',
    label: 'Zones',
    kind: 'special',
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
  },
  {
    name: 'owner',
    label: 'Futures Coin quantity',
    kind: 'special',
  },
  { name: 'borrow', label: 'Borrow', kind: 'checkbox' },
  {
    name: 'kind',
    label: 'Kind',
    kind: 'select',
    options: ['long', 'short'],
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
  },

  {
    name: 'type',
    label: 'Type',
    kind: 'select',
    options: ['open', 'close'],
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
  },
  {
    name: 'zones',
    label: 'Zones',
    kind: 'special',
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
  },
  {
    name: 'entry',
    label: 'Entry',
    kind: 'select',
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
    selectProps: { isDisabled: true },
    valueLayout: (v: any) => v.label,
    optionLayout: (v: any) => v.value,
  },
  {
    name: 'stop',
    label: 'Stop',
    kind: 'special',
    styles: { display: 'flex', flexDirection: 'row' },
    labelStyles: { alignSelf: 'end', fontWeight: 'semibold' },
    selectProps: { isDisabled: true },
    valueLayout: (v: any) => v.label,
    optionLayout: (v: any) => v.value,
  },
];
const config_fields: any = {
  config_1: [
    'percent',
    'stop_percent',
    'future_usdt_min_size',
    'future_coin_min_size',
  ],
  future_orders: ['kind', 'entry', 'stop'],
  margin_orders: ['kind', 'zones'],
};
export const MainTradeConfig = types
  .model('MainTradeConfig', {
    percent: types.optional(types.number, 2),
    future_usdt_min_size: types.optional(types.number, 0.001),
    future_coin_min_size: types.optional(types.number, 0.002),
    stop_percent: types.optional(types.number, 0.005),
  })
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        return getParamForField(self, configs, field, isGroup);
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export const MarginOrder = types
  .model('MarginOrder', {
    kind: types.optional(types.string, ''),
    type: types.optional(types.string, 'open'),
    zones: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let parent: ISolvedTraderConfig = getParent(self, 1);
        let result = getParamForField(self, configs, field, isGroup);

        if (parent.zones.length > 0) {
          if (['zones'].includes(field)) {
            result = {
              ...result,
              kind: 'select',
              options: parent.zones,
            };
          }
        }
        return result;
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        if (obj.zones) {
          obj.zones = parseFloat(obj.zones);
        }
        if (obj.entries) {
          obj.entries = parseFloat(obj.entries);
        }
        if (obj.stop) {
          obj.stop = parseFloat(obj.stop);
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export const FutureOrder = types
  .model('FutureOrder', {
    // zones: types.optional(types.number, 0),
    entry: types.optional(types.number, 0),
    stop: types.optional(types.number, 0),
    borrow: types.optional(types.boolean, false),
    kind: types.optional(types.string, ''),
  })
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let parent: ISolvedTraderConfig = getParent(self, 1);
        let result = getParamForField(self, configs, field, isGroup);
              if (['entry', 'stop'].includes(field)) {
          let results = parent.futureCTrader.getTradingZones({
            kind: self.kind,
          });
          result = {
            ...result,
            kind: 'select',
            selectProps: {
              isDisabled: self.kind ? false : true,
            },
            options: results.map((o, i) => {
              return {
                label: field === 'entry' ? o.entry : o.stop,
                value: field === 'entry' ? o.entry : o.stop,
              };
            }),
          };
        }

        if (parent.zones.length > 0) {
          if (['zones'].includes(field)) {
            result = { ...result, kind: 'select', options: parent.zones };
          }
        }
        return result;
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        if (obj.zones) {
          obj.zones = parseFloat(obj.zones);
        }
        if (obj.entry) {
          obj.entry = parseFloat(obj.entry);
        }
        if (obj.stop) {
          obj.stop = parseFloat(obj.stop);
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export interface IMainTradeConfig extends Instance<typeof MainTradeConfig> {}
export interface IMarginOrderConfig extends Instance<typeof MarginOrder> {}
export interface IFutureOrderConfig extends Instance<typeof FutureOrder> {}
